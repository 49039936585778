import gql from "graphql-tag";

export const setPassword = gql`
  mutation SetPassword($token: String!, $email: String!, $password: String!) {
    setPassword(token: $token, email: $email, password: $password) {
      errors {
        field
        message
      }
      accountErrors {
        field
        message
        code
      }
    }
  }
`;
