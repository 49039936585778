import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { generateCategoryUrl } from "../../core/utils";
import { Link } from "react-router-dom";
import { categoryProductsQuery } from "../ProductCategory/queries";
import Breadcrumbs from "../../components/Breadcrumbs";
import ImagePlaceholder from "../../assets/images/product-awaiting-image.png";

const CategoryList = ({ category, categories }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const { client } = useQuery(categoryProductsQuery, {
    skip: !category.node,
  });

  const prefetchCategory = (category) => {
    setTimeout(function () {
      client.query({
        query: categoryProductsQuery,
        variables: {
          categoryId: category.node.id,
          pageSize: 21,
          sortBy: {
            field: "NAME",
            direction: "ASC",
          },
        },
      });
    }, 500);
  };

  return (
    <section className="display">
      <Breadcrumbs data={category} type="CATEGORY" />
      <header className="container display__header">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h1 className="display__heading">{category.name}</h1>
          </div>
        </div>
      </header>

      <div className="display__body">
        <div className="container">
          <div className="display__grid">
            {categories.map(
              (category) =>
                category.node.products.totalCount > 0 && (
                  <Link
                    to={generateCategoryUrl(
                      category.node.id,
                      category.node.name
                    )}
                    className="category-card"
                    key={category.node.id}
                    onMouseOver={() => prefetchCategory(category)}
                  >
                    {!isImageLoaded && <img src={ImagePlaceholder} alt="" />}

                    <img
                      src={
                        category.node.backgroundImage
                          ? category.node.backgroundImage.url
                          : ImagePlaceholder
                      }
                      className={`category-card__image ${
                        isImageLoaded ? "d-block" : "d-none"
                      }`}
                      alt={category.node.name}
                      onLoad={() =>
                        setTimeout(() => setIsImageLoaded(true), 500)
                      }
                    />

                    <div className="category-card__title">
                      {category.node.name}
                    </div>
                  </Link>
                )
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CategoryList;
