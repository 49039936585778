import { useQuery } from "@apollo/react-hooks";
import Banner from "../../components/Banner";
import CategoryFeatured from "../../components/CategoryFeatured";
import Hero from "../../components/Hero";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import ProductsFeatured from "../../components/ProductsFeatured";
import RichTextContent from "../../components/RichTextContent";
import WindowTitle from "../../components/WindowTitle";
import CollectionPoints from "../../components/molecules/CollectionPointList/collectionPointList";
import HowItWorks from "../../components/molecules/HowItWorks/howItWorks";
import { homePageQuery } from "./queries";
import { HomePage, HomePageVariables } from "./types/HomePage";

const View = () => {
  const { loading, data, error } = useQuery<HomePage, HomePageVariables>(
    homePageQuery,
    {
      variables: { heroCollectionId: "Q29sbGVjdGlvbjoy" },
    }
  );
  if (loading || error || !data) return <Loader />;

  const { categories, heroCollection } = data;

  return (
    <Layout>
      <WindowTitle title="Home" />
      {heroCollection && (
        <Hero
          imageUrl={heroCollection.backgroundImage?.url}
          buttonText={heroCollection.heroButtonText}
          buttonUrl={heroCollection.heroButtonUrl}
          displayProductOfTheMonth={heroCollection.displayProductOfTheMonth}
        >
          <RichTextContent
            descriptionJson={heroCollection.descriptionJson}
          ></RichTextContent>
        </Hero>
      )}
      <ProductsFeatured></ProductsFeatured>
      <Banner></Banner>
      <CategoryFeatured categories={categories}></CategoryFeatured>
      <HowItWorks />
      <CollectionPoints />
    </Layout>
  );
};
export default View;
