import { Link } from "react-router-dom";
import ImagePlaceholder from "../../assets/images/product-awaiting-image.png";
import { generateProductUrl } from "../../core/utils";
import AddToCartButton from "../AddToCartButton";
import { FeaturedProducts_shop_homepageCollection_products_edges_node } from "../ProductsFeatured/types/FeaturedProducts";

interface ProductListItemProps {
  product: FeaturedProducts_shop_homepageCollection_products_edges_node;
}

const ProductListItem: React.FC<ProductListItemProps> = ({ product }) => {
  if (!product.isAvailable) {
    return null;
  }

  const getProductThumbnail = () => {
    return product.thumbnail ? product.thumbnail.url : ImagePlaceholder;
  };

  return (
    <>
      <div className="product-card">
        <Link
          className="product-card__block-link"
          to={generateProductUrl(product.id, product.name)}
        >
          {product.name}
        </Link>
        <div className="product-card__media">
          <img
            className="product-card__image"
            src={getProductThumbnail()}
            alt={product.name}
          ></img>
        </div>
        <div className="product-card__body">
          <div>
            <div className="product-card__title">{product.name}</div>
            <div className="product-card__subtitle">{product.subtitle}</div>
          </div>
          <div className="product-card__price">
            {product.pricing?.priceRange?.start?.gross.amount} pts
          </div>
        </div>
        <div className="product-card__drawer">
          {product.variants && product.variants[0] && (
            <AddToCartButton variant={product.variants[0]}></AddToCartButton>
          )}
        </div>
      </div>
    </>
  );
};
export default ProductListItem;
