import { useMutation } from "@apollo/client";
import { useState } from "react";
import { Link } from "react-router-dom";
import AlertInline from "../../components/AlertInline";
import Layout from "../../components/Layout";
import TextInput from "../../components/TextInput";
import WindowTitle from "../../components/WindowTitle";
import { errorFor } from "../../core/utils";
import { requestPasswordReset as requestPasswordResetQuery } from "./queries";
import "./styles.scss";
import {
  RequestPasswordReset,
  RequestPasswordResetVariables,
} from "./types/RequestPasswordReset";

const View = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState("");

  const [requestPasswordReset, { data, loading }] = useMutation<
    RequestPasswordReset,
    RequestPasswordResetVariables
  >(requestPasswordResetQuery, {
    onCompleted: (data) => {
      if (data.requestPasswordReset?.errors?.length === 0) {
        setEmailSent(true);
      } else {
        console.error(data.requestPasswordReset?.errors);
      }
    },
  });

  return (
    <Layout>
      <WindowTitle title="Request Password Reset" />
      <div className="container">
        <div className="request-password-reset">
          <h1>Request password reset</h1>
          {emailSent && (
            <AlertInline
              level="success"
              description="If an account with that email address exists, we have sent an
            email with a link to reset your password."
            />
          )}
          {!emailSent && (
            <form
              method="post"
              onSubmit={(e) => {
                e.preventDefault();
                requestPasswordReset({
                  variables: {
                    email,
                    redirectUrl: `${window.location.origin}/reset-password`,
                  },
                });
              }}
              className="form"
            >
              <div className="form__row">
                <TextInput
                  label="Email address"
                  type="email"
                  id="email"
                  placeholder="john.smith@example.com"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  autoFocus
                  required
                  error={errorFor("email", data?.requestPasswordReset?.errors)}
                />
              </div>
              <div className="request-password-reset__button">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  Request password reset
                </button>
              </div>
            </form>
          )}
          <Link className="request-password-reset__back-to-login" to="/login">
            &larr; Back to login
          </Link>
        </div>
      </div>
    </Layout>
  );
};
export default View;
