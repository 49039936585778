import { useMutation } from "@apollo/client";
import { useState } from "react";
import { Link } from "react-router-dom";
import AlertInline from "../../components/AlertInline";
import Layout from "../../components/Layout";
import TextInput from "../../components/TextInput";
import WindowTitle from "../../components/WindowTitle";
import useQueryParam from "../../core/hooks/useQueryParam";
import { errorFor } from "../../core/utils";
import { setPassword as setPasswordQuery } from "./queries";
import "./styles.scss";
import { SetPassword, SetPasswordVariables } from "./types/SetPassword";

const View = () => {
  const [newPassword, setNewPassword] = useState("");
  const [generalError, setGeneralError] = useState("");
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [token] = useQueryParam("token");
  const [email] = useQueryParam("email");
  const isValid = token && email;

  const [setPassword, { data, loading }] = useMutation<
    SetPassword,
    SetPasswordVariables
  >(setPasswordQuery, {
    onCompleted: (data) => {
      const tokenError =
        data.setPassword?.errors.find((err) => err?.field === "token") ??
        undefined;
      if (tokenError && tokenError.message) {
        setGeneralError(tokenError.message);
      }

      if (data.setPassword?.errors?.length === 0) {
        setPasswordChanged(true);
      } else {
        console.error({
          errors: data.setPassword?.errors,
          accountErrors: data.setPassword?.accountErrors,
        });
      }
    },
  });

  return (
    <Layout>
      <WindowTitle title="Reset Password" />
      <div className="container">
        <div className="password-reset">
          <h1>Reset password</h1>
          {passwordChanged && (
            <AlertInline
              level="success"
              description="Your password has been reset successfully. You may now login using your new password."
            />
          )}
          {!isValid && (
            <AlertInline
              level="error"
              description="Invalid password reset link."
            />
          )}
          {isValid && !passwordChanged && (
            <>
              {generalError && (
                <div className="form__row">
                  <AlertInline level="error" description={generalError} />
                </div>
              )}
              <form
                method="post"
                className="form"
                onSubmit={(e) => {
                  e.preventDefault();
                  setPassword({
                    variables: {
                      email,
                      password: newPassword,
                      token,
                    },
                  });
                }}
              >
                <div className="form__row">
                  <TextInput
                    label="Email"
                    type="email"
                    id="email"
                    value={email}
                    disabled
                    error={errorFor("email", data?.setPassword?.errors)}
                  />
                </div>
                <div className="form__row">
                  <TextInput
                    label="New password"
                    type="password"
                    id="password"
                    value={newPassword}
                    autoComplete="new-password"
                    required
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                    autoFocus
                    error={errorFor("password", data?.setPassword?.errors)}
                  />
                </div>
                <div className="password-reset__button">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    Reset password
                  </button>
                </div>
              </form>
            </>
          )}
          <Link className="password-reset__back-to-login" to="/login">
            &larr; Back to login
          </Link>
        </div>
      </div>
    </Layout>
  );
};
export default View;
