import React from "react";
import iconBoxOpen from "../../assets/images/icon-box-open.svg";
import iconCoin from "../../assets/images/icon-coin.svg";

const BagAccountSummary = ({ remainingPoints, remainingOrders }) => {
  return (
    <div className="bag__account-summary account-summary">
      <h2 className="account-summary__title">
        After placing this order you’ll have:
      </h2>
      <div className="account-summary__block">
        <div className="account-summary__block-row">
          <div className="account-summary__block-row-icon">
            <img
              src={iconCoin}
              alt="Icon - Open Box"
              className="account-summary__block-row-icon-img"
            />
          </div>
          <div className="account-summary__block-row-text">
            Monthly Points remaining:
          </div>
          <div
            className={
              "account-summary__block-row-data" +
              (remainingPoints <= 0
                ? " account-summary__block-row-data--error"
                : "")
            }
          >
            {remainingPoints}
          </div>
        </div>
        <div className="account-summary__block-row">
          <div className="account-summary__block-row-icon">
            <img
              src={iconBoxOpen}
              alt="Icon - Open Box"
              className="account-summary__block-row-icon-img"
            />
          </div>
          <div className="account-summary__block-row-text">
            Monthly Orders remaining:
          </div>
          <div
            className={
              "account-summary__block-row-data" +
              (remainingOrders <= 0
                ? " account-summary__block-row-data--error"
                : "")
            }
          >
            {remainingOrders}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BagAccountSummary;
