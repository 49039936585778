import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useGlobal } from "reactn";
import {
  createCheckout,
  addCheckoutLines,
  userCheckoutDetails,
} from "../../views/Checkout/queries";
import { getUserDetailsQuery } from "../../views/Account/queries";
import AlertInline from "../../components/AlertInline";

const AddToCartButton = ({ variant, buttonClass = "" }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [, setPointsInBag] = useGlobal("pointsInBag");
  const [, setItemsInBag] = useGlobal("itemsInBag");
  const {
    loading: checkoutLoading,
    data: checkoutData,
    refetch: refetchCheckoutData,
  } = useQuery(userCheckoutDetails);
  const { data: userData } = useQuery(getUserDetailsQuery);
  const [createCheckoutMutation] = useMutation(createCheckout);
  const [addCheckoutLinesMutation] = useMutation(addCheckoutLines);

  useEffect(() => {
    if (checkoutLoading) return;
    if (!checkoutData) return;

    const getItemsInBag = () => {
      return checkoutData.me.checkout && checkoutData.me.checkout.lines.length;
    };

    const getPointsInBag = () => {
      return checkoutData.me.checkout?.subtotalPrice.gross.amount || 0;
    };

    setPointsInBag(getPointsInBag());
    setItemsInBag(getItemsInBag());
  }, [setItemsInBag, setPointsInBag, checkoutLoading, checkoutData]);

  if (!checkoutData) return null;

  function isInCart() {
    return (
      checkoutData.me.checkout &&
      checkoutData.me.checkout.lines.filter(
        (line) => line.variant.id === variant.id
      ).length !== 0
    );
  }

  const outOfStock = () => {
    return variant.stockQuantity - variant.quanityAllocatedInCheckout === 0;
  };

  const notEnoughPoints = () => {
    return (
      checkoutData.me.points -
        (checkoutData.me.checkout?.subtotalPrice.gross.amount || 0) <
      variant.pricing.price.gross.amount
    );
  };

  async function addToCart() {
    setIsAdding(true);
    if (checkoutData.me.checkout === null) {
      await addToNewCheckout(variant.id, 1);
      await refetchCheckoutData();
    } else {
      await addToExistingCheckout(variant.id, 1);
    }
    setIsAdding(false);
  }

  async function addToNewCheckout(variantId, quantity) {
    const result = await createCheckoutMutation({
      variables: {
        checkoutInput: {
          email: userData.me.email,
          lines: [
            {
              quantity: quantity,
              variantId: variantId,
            },
          ],
        },
      },
    });

    if (result.data.checkoutCreate.errors.length) {
      if (result.data.checkoutCreate.errors[0].field === "quantity") {
        // if an item goes out of stock while you are on the product page
        // update the button to 'Out of Stock'
        setIsOutOfStock(true);
      } else {
        setErrorMessage(result.data.checkoutCreate.errors[0].message);
      }
      console.error(
        "Error adding item to cart: ",
        result.data.checkoutCreate.errors
      );
    }

    if (result.data.checkoutCreate.checkoutErrors.length) {
      if (
        result.data.checkoutCreate.checkoutErrors[0].code ===
        "MONTHLY_PRODUCT_LIMIT_REACHED"
      ) {
        monthlyProductLimitReached();
      }
    }
  }

  function monthlyProductLimitReached() {
    setErrorMessage("You cannot purchase any more of this product this month.");
  }

  async function addToExistingCheckout(variantId, quantity) {
    const result = await addCheckoutLinesMutation({
      variables: {
        checkoutId: checkoutData.me.checkout.id,
        lines: [
          {
            quantity: quantity,
            variantId: variantId,
          },
        ],
      },
    });

    if (result.data.checkoutLinesAdd.errors.length) {
      if (result.data.checkoutLinesAdd.errors[0].field === "quantity") {
        // if an item goes out of stock while you are on the product page
        // update the button to 'Out of Stock'
        setIsOutOfStock(true);
      }
      if (result.data.checkoutLinesAdd.errors[0].field === "maxQuantity") {
        // if more that 1 item of a product is added show an error
        setErrorMessage(result.data.checkoutLinesAdd.errors[0].message);
      }
      if (result.data.checkoutLinesAdd.errors[0].field === null) {
        // There's a custom error, so display it
        setErrorMessage(result.data.checkoutLinesAdd.errors[0].message);
      }
      console.error(
        "Error adding item to cart: ",
        result.data.checkoutLinesAdd.errors
      );
    }

    if (result.data.checkoutLinesAdd.checkoutErrors.length) {
      if (
        result.data.checkoutLinesAdd.checkoutErrors[0].code ===
        "MONTHLY_PRODUCT_LIMIT_REACHED"
      ) {
        monthlyProductLimitReached();
      }
    }
  }

  if (checkoutLoading) {
    return (
      <button className={`btn btn--loading ${buttonClass}`}>
        <span>Loading</span>
      </button>
    );
  }

  if (isOutOfStock || !isInCart() & outOfStock()) {
    return <span>Out of stock</span>;
  }

  if (isInCart()) {
    return (
      <button className={`btn btn--inactive btn--grey ${buttonClass}`}>
        <span>Added</span>
      </button>
    );
  }

  if (notEnoughPoints()) {
    return (
      <button className={`btn btn--inactive btn--grey ${buttonClass}`}>
        <span>Not enough points</span>
      </button>
    );
  }

  if (isAdding) {
    return (
      <button
        className={`btn btn--inactive btn--grey btn--loading ${buttonClass}`}
      >
        <span>Adding</span>
      </button>
    );
  }

  return (
    <div>
      <button
        className={`btn btn--secondary ${buttonClass}`}
        onClick={() => {
          addToCart();
        }}
      >
        <span>Add to bag</span>
      </button>

      {errorMessage !== "" && (
        <AlertInline level="error" description={errorMessage} />
      )}
    </div>
  );
};

export default AddToCartButton;
