import React from "react";

import "../Layout/index.scss";

const LayoutBlank = ({ children }) => {
  return (
    <>
      <div className="layout">
        <div className="layout__content">{children}</div>
        <div className="layout__footer"></div>
      </div>
    </>
  );
};

export default LayoutBlank;
