import React from "react";
import { generateCategoryUrl } from "../../core/utils";
import { Link } from "react-router-dom";

const CategoryFeatured = (data) => {
  return (
    <section className="promo">
      <header className="container promo__header">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h2 className="promo__title">Shop by category</h2>
          </div>
        </div>
      </header>
      <div className="container promo__body">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="promo__list">
              {data.categories.edges.map((category) => (
                <div
                  className="promo__list-item cta-card"
                  key={category.node.id}
                >
                  <div className="cta-card__media">
                    <img
                      src={category.node.backgroundImage.url}
                      alt={category.node.name}
                    ></img>
                  </div>
                  <div className="cta-card__body">
                    <h3 className="cta-card__title">{category.node.name}</h3>
                    <div className="cta-card__actions">
                      <Link
                        to={generateCategoryUrl(
                          category.node.id,
                          category.node.name
                        )}
                        className="btn btn--secondary cta-card__btn"
                      >
                        Shop Now
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CategoryFeatured;
