import React from "react";
import { useGlobal } from "reactn";
import { Link } from "react-router-dom";

const QuickSearchListItem = (props) => {
  const [quickSearchOpen, setQuickSearchOpen] = useGlobal("quickSearchOpen");

  const handleNavigateClick = () => {
    const searchOpen = !quickSearchOpen;
    setQuickSearchOpen(searchOpen);
  };

  const { imageURL, imageAlt, productLink, title, subTitle, price } = props;
  return (
    <Link
      to={productLink}
      className="quicksearch-item"
      onClick={handleNavigateClick}
    >
      <div className="quicksearch-item__media">
        <img src={imageURL} alt={imageAlt} />
      </div>
      <div className="quicksearch-item__body">
        <h3 className="quicksearch-item__title">{title}</h3>
        <div className="quicksearch-item__subtitle">{subTitle}</div>
        <div className="quicksearch-item__points">{price} pts</div>
      </div>
    </Link>
  );
};
export default QuickSearchListItem;
