import gql from "graphql-tag";

const checkoutPriceFragment = gql`
  fragment Price on TaxedMoney {
    gross {
      amount
      currency
      localized
    }
    net {
      amount
      currency
      localized
    }
  }
`;

export const checkoutAddressFragment = gql`
  fragment Address on Address {
    id
    firstName
    lastName
    companyName
    streetAddress1
    streetAddress2
    city
    postalCode
    country {
      code
      country
    }
    countryArea
    phone
    isDefaultBillingAddress
    isDefaultShippingAddress
  }
`;

export const checkoutProductVariantFragment = gql`
  ${checkoutPriceFragment}
  fragment ProductVariant on ProductVariant {
    id
    name
    isProductOfTheMonth
    pricing {
      onSale
      priceUndiscounted {
        ...Price
      }
      price {
        ...Price
      }
    }
    product {
      id
      name
      subtitle
      thumbnail {
        url
        alt
      }
      thumbnail2x: thumbnail(size: 510) {
        url
      }
    }
  }
`;

const checkoutShippingMethodFragment = gql`
  fragment ShippingMethod on ShippingMethod {
    id
    name
    price {
      currency
      amount
      localized
    }
  }
`;

const checkoutLineFragment = gql`
  ${checkoutPriceFragment}
  ${checkoutProductVariantFragment}
  fragment CheckoutLine on CheckoutLine {
    id
    quantity
    totalPrice {
      ...Price
    }
    variant {
      stockQuantity
      quanityAllocatedInCheckout
      ...ProductVariant
    }
    quantity
  }
`;

const checkoutMetadataFragment = gql`
  fragment Metadata on Checkout {
    meta {
      namespace
      clients {
        name
        metadata {
          key
          value
        }
      }
    }
  }
`;

const checkoutFragment = gql`
  ${checkoutLineFragment}
  ${checkoutShippingMethodFragment}
  ${checkoutMetadataFragment}
  fragment Checkout on Checkout {
    token
    id
    created
    totalPrice {
      ...Price
    }
    subtotalPrice {
      ...Price
    }
    email
    availableShippingMethods {
      ...ShippingMethod
    }
    shippingMethod {
      ...ShippingMethod
    }
    lines {
      ...CheckoutLine
    }
    ...Metadata
  }
`;

export const checkoutDetails = gql`
  ${checkoutFragment}
  query CheckoutDetails($token: UUID!) {
    checkout(token: $token) {
      ...Checkout
    }
  }
`;

export const userCheckoutDetails = gql`
  ${checkoutFragment}
  query UserCheckoutDetails {
    me {
      id
      points
      orderAllowance
      checkout {
        ...Checkout
      }
    }
  }
`;

export const createPayment = gql`
  mutation createPayment($input: PaymentInput!, $checkoutId: ID!) {
    checkoutPaymentCreate(input: $input, checkoutId: $checkoutId) {
      errors {
        field
        message
        __typename
      }
      __typename
    }
  }
`;

export const updateCheckoutShippingOptions = gql`
  mutation updateCheckoutShippingOptions(
    $checkoutId: ID!
    $shippingMethodId: ID!
  ) {
    checkoutShippingMethodUpdate(
      checkoutId: $checkoutId
      shippingMethodId: $shippingMethodId
    ) {
      errors {
        field
        message
        __typename
      }
      __typename
    }
  }
`;

export const completeCheckout = gql`
  mutation completeCheckout($checkoutId: ID!) {
    checkoutComplete(checkoutId: $checkoutId) {
      errors {
        field
        message
        __typename
      }
      order {
        id
        token
        __typename
      }
      __typename
    }
  }
`;

export const getCheckout = gql`
  ${checkoutFragment}
  query getCheckout($token: UUID!) {
    checkout(token: $token) {
      ...Checkout
    }
  }
`;

export const updateCheckoutLine = gql`
  ${checkoutLineFragment}
  ${checkoutPriceFragment}
  mutation updateCheckoutLine($checkoutId: ID!, $lines: [CheckoutLineInput]!) {
    checkoutLinesUpdate(checkoutId: $checkoutId, lines: $lines) {
      checkout {
        id
        lines {
          ...CheckoutLine
        }
        subtotalPrice {
          ...Price
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const deleteCheckoutLine = gql`
  ${checkoutLineFragment}
  ${checkoutPriceFragment}
  mutation checkoutLineDelete($checkoutId: ID!, $lineId: ID!) {
    checkoutLineDelete(checkoutId: $checkoutId, lineId: $lineId) {
      checkout {
        id
        lines {
          ...CheckoutLine
        }
        subtotalPrice {
          ...Price
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const updateCheckoutMetadata = gql`
  mutation CheckoutUpdateMetadata($input: MetaInput!, $checkoutToken: UUID!) {
    checkoutUpdateMetadata(input: $input, token: $checkoutToken) {
      errors {
        field
        message
      }
    }
  }
`;

export const checkoutCustomerDetach = gql`
  mutation checkoutCustomerDetach($checkoutId: ID!) {
    checkoutCustomerDetach(checkoutId: $checkoutId) {
      checkout {
        id
      }
    }
  }
`;

export const createCheckout = gql`
  ${checkoutFragment}
  mutation createCheckout($checkoutInput: CheckoutCreateInput!) {
    checkoutCreate(input: $checkoutInput) {
      errors {
        field
        message
      }
      checkoutErrors {
        field
        message
        code
      }
      checkout {
        ...Checkout
      }
    }
  }
`;

export const getUserCheckout = gql`
  ${checkoutFragment}
  query getUserCheckout {
    me {
      checkout {
        ...Checkout
      }
    }
  }
`;

export const addCheckoutLines = gql`
  ${checkoutLineFragment}
  ${checkoutPriceFragment}
  mutation addCheckoutLines($checkoutId: ID!, $lines: [CheckoutLineInput]!) {
    checkoutLinesAdd(checkoutId: $checkoutId, lines: $lines) {
      checkout {
        id
        lines {
          ...CheckoutLine
        }
        subtotalPrice {
          ...Price
        }
      }
      errors {
        field
        message
      }
      checkoutErrors {
        field
        message
        code
      }
    }
  }
`;
