import React from "react";
import "./styles.scss";

export type TextInputProps = {
  id: string;
  label: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  placeholder?: string;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  className?: string;
  error?: string;
  help?: React.ReactNode;
  autoComplete?: string;
};

const TextInput: React.FC<TextInputProps> = ({
  id,
  label,
  value,
  onChange,
  type = "text",
  placeholder = "",
  required = false,
  autoFocus = false,
  disabled = false,
  className = "",
  error = "",
  help = "",
  autoComplete,
}) => {
  return (
    <div className={`text-input ${className}`}>
      <label className="text-input__label" htmlFor={id}>
        {label}
      </label>
      <input
        type={type}
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`text-input__input ${error && "text-input__input--error"}`}
        required={required}
        autoFocus={autoFocus}
        disabled={disabled}
        autoComplete={autoComplete}
      />
      {error && <div className="text-input__error">{error}</div>}
      {help && <div className={`text-input__help`}>{help}</div>}
    </div>
  );
};

export default TextInput;
