import { useEffect } from "react";
import { Route, RouteProps } from "react-router-dom";
import { useAuth } from "../../core/hooks/useAuth";
import Loader from "../Loader";

export type SecureRouteProps = {} & RouteProps;

export default function SecureRoute({ ...routeProps }: SecureRouteProps) {
  const { isAuthenticated, loginViaOkta } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      loginViaOkta();
    }
  }, [isAuthenticated, loginViaOkta]);

  if (isAuthenticated) {
    return <Route {...routeProps} />;
  }

  return <Loader />;
}
