import gql from "graphql-tag";

export const getPage = gql`
  query Page($slug: String!) {
    page(slug: $slug) {
      contentJson
      id
      seoDescription
      seoTitle
      slug
      title
    }
  }
`;
