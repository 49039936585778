import gql from "graphql-tag";

import {
  basicProductFragment,
  productPricingFragment,
  productVariantFragment,
} from "../../views/Product/queries";

export const categoryProductsQuery = gql`
  ${basicProductFragment}
  ${productPricingFragment}
  ${productVariantFragment}
  query CategoryProductsQuery(
    $categoryId: ID!
    $pageSize: Int
    $after: String
    $sortBy: ProductOrder
  ) {
    products(
      first: $pageSize
      after: $after
      sortBy: $sortBy
      filter: { categories: [$categoryId] }
      stockAvailability: IN_STOCK
    ) {
      totalCount
      edges {
        node {
          ...BasicProductFields
          ...ProductPricingField
          category {
            id
            name
          }
          variants {
            ...ProductVariantFields
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
