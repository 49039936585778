import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { orderDetailsByIdQuery } from "./queries";
import Layout from "../../components/Layout";
import CollectionPointListItem from "../../components/atoms/CollectionPointItem/collectionPointItem";
import { Link } from "react-router-dom";
import NotFound from "../NotFound";
import Loader from "../../components/Loader";
import WindowTitle from "../../components/WindowTitle";
import { getCollectionPointById } from "../../config/collectionPoints";

const View = ({ match }) => {
  const { loading, data, error } = useQuery(orderDetailsByIdQuery, {
    variables: { id: match.params.id },
  });

  if (loading) return <Loader />;
  if (error || !data.order) return <NotFound />;

  const collectionPoint = getCollectionPointById(data.order.shippingMethod.id);

  return (
    <Layout>
      <WindowTitle title="Order Confirmed" />
      <div className="order-confirmation">
        <div className="container order-confirmation__body">
          <div className="progress-steps__progress">
            <div className="progress-steps__step progress-steps__step--active complete">
              <div className="progress-steps__step-circle"></div>
              <span className="progress-steps__step-title">
                Review &amp; Confirm
              </span>
            </div>

            <div className="progress-steps__step progress-steps__step--active complete">
              <div className="progress-steps__step-circle"></div>
              <span className="progress-steps__step-title">Complete</span>
            </div>
          </div>

          <div className="order-confirmation__content">
            <h1 className="order-confirmation__title">
              Woohoo {data.order.user.firstName || data.order.userEmail}!
            </h1>
            <h2 className="order-confirmation__subtitle">
              Your order is confirmed
            </h2>
            <p className="order-confirmation__text">
              Enjoy your doTERRA product(s) and thanks again for all your hard
              work!
            </p>
            <div className="order-confirmation__badge">
              Order number: {data.order.number}
            </div>
            <p className="order-confirmation__text">
              The Employee Store will send you a notification when your order is
              ready to be collected
            </p>
          </div>
        </div>
        <footer className="order-confirmation__footer">
          <div className="container">
            <div className="order-confirmation__location">
              <CollectionPointListItem
                collectionPoint={collectionPoint}
              ></CollectionPointListItem>
            </div>

            <Link
              to="/"
              className="btn btn--grey-light order-confirmation__btn"
            >
              Continue browsing
            </Link>
          </div>
        </footer>
      </div>
    </Layout>
  );
};

export default View;
