import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { updateCheckoutShippingOptions } from "../../views/Checkout/queries";
import { getCollectionPointById } from "../../config/collectionPoints";
import AlertInline from "../../components/AlertInline";

const CollectionPointPicker = ({
  checkoutId,
  collectionPoints,
  selectedCollectionPoint,
  setSelectedCollectionPoint,
}) => {
  const [updateCheckoutShippingOptionsMutation] = useMutation(
    updateCheckoutShippingOptions
  );

  async function changeCollectionPoint(collectionPoint) {
    await updateCheckoutShippingOptionsMutation({
      variables: {
        checkoutId,
        shippingMethodId: collectionPoint.id,
      },
    });

    setSelectedCollectionPoint(collectionPoint);
  }

  const collectionPointAlert = () => {
    const alertText = getCollectionPointById(
      selectedCollectionPoint?.id
    )?.alertText;

    return alertText ? (
      <AlertInline
        className="collection-point__picker-alert"
        level="error"
        description={alertText}
      />
    ) : (
      ""
    );
  };

  return (
    <div className="collection-point__picker">
      {collectionPoints.map((collectionPoint) => (
        <div key={collectionPoint.id} className="collection-point__picker-row">
          <input
            type="radio"
            value={collectionPoint.id}
            id={collectionPoint.id}
            checked={selectedCollectionPoint?.id === collectionPoint.id}
            onChange={() => changeCollectionPoint(collectionPoint)}
            name="shipping_method"
            className="collection-point__radio"
          />{" "}
          <label
            htmlFor={collectionPoint.id}
            className="collection-point__radio-label"
          >
            {collectionPoint.name}
          </label>
        </div>
      ))}
      {collectionPointAlert()}
    </div>
  );
};

export default CollectionPointPicker;
