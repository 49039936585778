import { sanitize } from "dompurify";
import draftToHtml from "draftjs-to-html";
import React from "react";

const RichTextContent = ({ descriptionJson }) => (
  <>
    {descriptionJson && (
      <div
        className="page__content-inner"
        dangerouslySetInnerHTML={{
          __html: sanitize(draftToHtml(JSON.parse(descriptionJson))),
        }}
      />
    )}
  </>
);

export default RichTextContent;
