import gql from "graphql-tag";

const orderDetailFragment = gql`
  fragment OrderDetail on Order {
    id
    number
    userEmail
    user {
      id
      firstName
      lastName
      email
    }
    shippingMethod {
      name
      id
    }
  }
`;

export const orderDetailsByIdQuery = gql`
  ${orderDetailFragment}
  query OrderById($id: ID!) {
    order(id: $id) {
      ...OrderDetail
    }
  }
`;

export const orderDetailsByTokenQuery = gql`
  ${orderDetailFragment}
  query OrderByToken($token: UUID!) {
    orderByToken(token: $token) {
      ...OrderDetail
    }
  }
`;
