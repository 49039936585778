import React, { useState } from "react";
import { useGlobal } from "reactn";
import QuickSearchList from "./quickSearchList";
import { ReactSVG } from "react-svg";
import crossIcon from "../../../assets/icons/icon-cross.svg";
import { debounce } from "lodash";

import "./quickSearch.scss";

const QuickSearch = () => {
  const [quickSearchOpen, setQuickSearchOpen] = useGlobal("quickSearchOpen");
  const [searchTerm, setSearchTerm] = useState("");

  const searchClasses = `quickSearch ${
    quickSearchOpen ? "quickSearch__isOpen" : null
  }`;

  const handleCloseClick = () => {
    const searchOpen = !quickSearchOpen;
    setQuickSearchOpen(searchOpen);
  };

  const handleClearClick = () => {
    setSearchTerm("");
  };

  const delayedHandleChange = debounce(
    (eventData) => setSearchTerm(eventData),
    1000
  );

  const handleQuickSearch = (event) => {
    delayedHandleChange(event);
  };
  const handleSearch = (event) => {
    if (event.key === "Enter") {
      window.location = "/search?q=" + searchTerm;
    }
  };

  return (
    <section className={searchClasses}>
      <header className="quickSearch__header">
        <div className="quickSearch__searchholder">
          <input
            id="quicksearch_input"
            type="text"
            placeholder="Search"
            onChange={(event) => handleQuickSearch(event.target.value)}
            onKeyDown={(event) => handleSearch(event)}
          ></input>
        </div>
        {searchTerm.length > 0 && (
          <button
            className="quickSearch__clear"
            title="Clear Search"
            onClick={handleClearClick}
          >
            Clear
          </button>
        )}
        <button
          className="quickSearch__remove"
          title="Close Search"
          onClick={handleCloseClick}
        >
          <ReactSVG src={crossIcon} wrapper="span" />
        </button>
      </header>
      <main className="quickSearch__main">
        {searchTerm && <QuickSearchList query={searchTerm} />}
      </main>
      {searchTerm.length > 0 && (
        <footer className="quickSearch__footer">
          <a
            className="btn btn-primary btn-size-lg"
            href={"/search?q=" + searchTerm}
          >
            Show all results
          </a>
        </footer>
      )}
    </section>
  );
};

export default QuickSearch;
