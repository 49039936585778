import { useQuery } from "@apollo/react-hooks";
import ProductListItem from "../ProductListItem";
import { featuredProducts } from "./queries";
import { FeaturedProducts } from "./types/FeaturedProducts";

const ProductsFeatured = () => {
  const { loading, data, error } = useQuery<FeaturedProducts>(featuredProducts);
  if (loading || error) return null;

  const products = data?.shop?.homepageCollection?.products;

  if (!products) return null;

  return (
    <section className="featured-products">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="featured-products__title">Featured Products</h2>

            <div className="featured-products__list">
              {products.edges.map((product) => (
                <ProductListItem
                  product={product.node}
                  key={product.node.id}
                ></ProductListItem>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductsFeatured;
