import LocationPleasantGroveImage from "../assets/images/location-pleasant-grove.jpg";
import LocationLindonImage from "../assets/images/location-lindon.jpg";
import LocationCultureTeamImage from "../assets/images/location-culture-team.jpg";

export const collectionPoints = [
  {
    id: "U2hpcHBpbmdNZXRob2Q6Nw==",
    name: "Pleasant Grove Campus",
    imageURL: LocationPleasantGroveImage,
    description:
      "<p>The Employee Store pick-up location is located just to the east of the Product Center. The entrance is badge access only so don’t forget to bring your badge!</p>",
    openingHours: "Open Monday to Friday, 10:00 a.m. to 6:00 p.m.",
    mapURL:
      "https://www.google.com/maps/place/d%C5%8DTERRA+Product+Center/@40.3573861,-111.761316,17z/data=!3m1!4b1!4m5!3m4!1s0x874d9ad0ff5f8be1:0xb0f217f123ab1c0e!8m2!3d40.3573861!4d-111.7591273",
    alertText: null,
  },
  {
    id: "U2hpcHBpbmdNZXRob2Q6OA==",
    name: "Lindon Fulfillment Centre",
    imageURL: LocationLindonImage,
    description:
      "<p>The Employee Store pick-up location is located on the ground level near the security entrance to the fulfillment floor.</p> <p>Campus access required to pickup at this location</p>",
    openingHours: "Open Monday to Friday, 9:00 a.m. to 4:00 p.m.",
    mapURL:
      "https://www.google.com/maps/place/DoTERRA+Fulfillment+Center/@40.345805,-111.7660408,15z/data=!4m5!3m4!1s0x0:0x5c2e9163ea25cd06!8m2!3d40.345805!4d-111.7660408",
    alertText: null,
  },
  {
    id: "U2hpcHBpbmdNZXRob2Q6OQ==",
    name: "Culture Team: Member Services Only",
    imageURL: LocationCultureTeamImage,
    description: "",
    openingHours: "",
    mapURL: "",
    alertText:
      "If you have selected this option, but do not have approval from the culture team your items may be restocked.",
  },
];

export function getCollectionPointById(id) {
  return collectionPoints.find((p) => p.id === id);
}
