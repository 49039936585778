import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useGlobal } from "reactn";
import accountIcon from "../../assets/icons/icon-account.svg";
import coinIcon from "../../assets/icons/icon-coin.svg";
import { getNavLinkUrl } from "../../core/utils";
import { ShopContext } from "../ShopProvider/context";

type MainMenuProps = {
  pointsAvailable: number;
  pointsInBag: number;
  pointsRemaining: number;
  isUpdatingPointsSummary: boolean;
};

const MainMenu: React.FC<MainMenuProps> = ({
  pointsAvailable,
  pointsInBag,
  pointsRemaining,
  isUpdatingPointsSummary,
}) => {
  const { shop } = useContext(ShopContext);
  const [, setMobileMenuOpen] = useGlobal("mobileMenuOpen");

  if (!shop) return null;

  const menu = shop?.navigation?.main;

  return (
    <nav className="nav nav--main">
      <ul className="nav__menu">
        <li className="nav__item nav__item--header">
          <Link
            className="nav__link"
            to="/account"
            onClick={() => setMobileMenuOpen(false)}
          >
            <img
              className="nav__link-icon"
              width="18"
              src={accountIcon}
              alt=""
            />{" "}
            Account
          </Link>
        </li>
        <li className="nav__item nav__item--points-display">
          <span className="nav__item-label">
            <img className="nav__link-icon" width="18" src={coinIcon} alt="" />{" "}
            Points summary
          </span>
          {isUpdatingPointsSummary ? (
            ""
          ) : (
            <div>
              <div className="nav__item-data">
                Available:{" "}
                <span className="nav__item-data-value">{pointsAvailable}</span>
              </div>
              <div className="nav__item-data">
                In my bag:{" "}
                <span className="nav__item-data-value">{pointsInBag}</span>
              </div>
              <div className="nav__item-data">
                Remaining:{" "}
                <span className="nav__item-data-value">{pointsRemaining}</span>
              </div>
            </div>
          )}
        </li>
        {menu &&
          menu.items?.map((item) => (
            <li className="nav__item" key={item?.id}>
              <Link
                className="nav__link"
                to={getNavLinkUrl(item)}
                onClick={() => setMobileMenuOpen(false)}
              >
                {item?.name}
              </Link>
            </li>
          ))}
      </ul>
    </nav>
  );
};

export default MainMenu;
