import { useState } from "react";
import { ReactSVG } from "react-svg";
import boxOpenIcon from "../../assets/icons/icon-box-open.svg";
import boxIcon from "../../assets/icons/icon-box.svg";
import ImagePlaceholder from "../../assets/images/product-awaiting-image.png";
import "./index.scss";

const OrderHistoryItem = ({ order, index }) => {
  const [isOpen, setIsOpen] = useState(0);

  const stateClass =
    index === 0 || isOpen ? "order-block order-block--open" : "order-block";

  const icon = index === 0 || isOpen ? boxOpenIcon : boxIcon;

  const toggleOpen = () => setIsOpen(!isOpen);

  const orderDateFormatted = () => {
    let date = new Date(order.node.created);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const transformStatus = (fulfilmentStatus, printedAt, pickedUpAt) => {
    if (fulfilmentStatus === "Fulfilled") {
      if (pickedUpAt) {
        return "Picked up";
      }

      return "Awaiting Pickup";
    }
    if (fulfilmentStatus === "Unfulfilled") {
      if (printedAt) {
        return "Preparing";
      }

      return "Pending";
    }
    return fulfilmentStatus;
  };

  const getProductThumbnail = (line) => {
    return line.thumbnail ? line.thumbnail.url : ImagePlaceholder;
  };
  return (
    <div className={stateClass} onClick={toggleOpen}>
      <header className="order-block__row">
        <ReactSVG src={icon} wrapper="div" className="order-block__row-icon" />
        <div className="order-block__row-body">
          <div className="order-block__number">
            Order No.: {order.node.number}
          </div>
          <div className="order-block__details">
            <div className="order-block__detail-item">
              <span className="order-block__detail-label">Placed:</span>
              <span className="order-block__detail-info">
                {orderDateFormatted()}
              </span>
            </div>
            <div className="order-block__detail-item">
              <span className="order-block__detail-label">Points Total:</span>
              <span className="order-block__detail-info">
                {order.node.total.gross.amount}
              </span>
            </div>
            <div className="order-block__detail-item">
              <span className="order-block__detail-label">Status:</span>
              <span className="order-block__detail-info">
                {transformStatus(
                  order.node.statusDisplay,
                  order.node.printedAt,
                  order.node.pickedUpAt
                )}
              </span>
            </div>
          </div>
        </div>
      </header>
      <div className="order-block__body">
        {order.node.lines.map((line, i) => (
          <div key={i} className="order-block__product-item">
            <img
              className="order-block__product-item-img"
              src={getProductThumbnail(line)}
              alt={line.productName}
              title={line.productName}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default OrderHistoryItem;
