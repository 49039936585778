import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import WindowTitle from "../../components/WindowTitle";
import "./styles.scss";

const View = () => {
  return (
    <Layout>
      <WindowTitle title="Page Not Found" />
      <div className="not-found">
        <section className="display">
          <header className="container display__header">
            <h1 className="display__heading">Page not found</h1>
            <p className="text-center">
              Sorry, the page you're looking for could not be found.{" "}
              <Link to="/">Return to the homepage</Link>
            </p>
          </header>
        </section>
      </div>
    </Layout>
  );
};

export default View;
