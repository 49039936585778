import gql from "graphql-tag";

export const homePageQuery = gql`
  query HomePage($heroCollectionId: ID!) {
    shop {
      description
      name
      homepageCollection {
        id
        backgroundImage {
          url
        }
        name
      }
    }
    categories(level: 0, first: 4) {
      edges {
        node {
          id
          name
          backgroundImage {
            url
          }
        }
      }
    }
    heroCollection: collection(id: $heroCollectionId) {
      id
      name
      descriptionJson
      displayProductOfTheMonth
      heroButtonText
      heroButtonUrl
      backgroundImage {
        url
      }
    }
    heroProducts: products(
      filter: { collections: [$heroCollectionId] }
      first: 1
    ) {
      edges {
        node {
          id
          name
          images {
            id
            url
            alt
          }
        }
      }
    }
  }
`;
