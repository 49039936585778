import React from "react";
import ReactDOM from "react-dom";
import { setGlobal } from "reactn";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "./index.css";
import App from "./App";

setGlobal({
  mobileMenuOpen: false,
  quickSearchOpen: false,
  fullSearchCount: 0,
  itemsInBag: 0,
  pointsInBag: 0,
});

ReactDOM.render(<App />, document.getElementById("root"));
