import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import { generateCategoryUrl } from "../../core/utils";

const Breadcrumbs = (data) => {
  return (
    <div className="breadcrumb">
      <Link to="/" className="breadcrumb__link">
        Home
      </Link>
      {data.type === "PRODUCT" && (
        <>
          <span className="breadcrumb__seperator"> / </span>
          <Link
            to={generateCategoryUrl(
              data.data.category.id,
              data.data.category.name
            )}
            className="breadcrumb__link"
          >
            {data.data.category.name}
          </Link>
        </>
      )}
      <span className="breadcrumb__seperator"> / </span>
      <span className="breadcrumb__title">{data.data.name}</span>
    </div>
  );
};

export default Breadcrumbs;
