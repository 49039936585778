// @ts-ignore
import { Base64 } from "js-base64";

export const slugify = (text: any) =>
  text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/--+/g, "-"); // Replace multiple - with single -

export const getDBIdFromGraphqlId = (graphqlId: any, schema: any) => {
  // This is temporary solution, we will use slugs in the future
  const rawId = Base64.decode(graphqlId);
  const regexp = /(\w+):(\d+)/;
  const arr = regexp.exec(rawId);
  if (!arr) {
    throw new Error("Invalid graphql id");
  }
  if (schema && schema !== arr[1]) {
    throw new Error("Schema is not correct");
  }
  return parseInt(arr[2], 10);
};

export const getGraphqlIdFromDBId = (id: any, schema: any) =>
  // This is temporary solution, we will use slugs in the future
  Base64.encode(`${schema}:${id}`);

export const generateProductUrl = (id: any, name: any) =>
  `/product/${slugify(name)}/${getDBIdFromGraphqlId(id, "Product")}/`;

export const generateCategoryUrl = (id: any, name: any) =>
  `/category/${slugify(name)}/${getDBIdFromGraphqlId(id, "Category")}/`;

export const generateCollectionUrl = (id: any, name: any) =>
  `/collection/${slugify(name)}/${getDBIdFromGraphqlId(id, "Collection")}/`;

export const generatePageUrl = (slug: any) => `/page/${slug}/`;

export const getNavLinkUrl = (link: any) => {
  const { url, category, collection, page } = link;

  if (url) {
    return url;
  } else if (category) {
    return generateCategoryUrl(category.id, category.name);
  } else if (collection) {
    return generateCollectionUrl(collection.id, collection.name);
  } else if (page) {
    return generatePageUrl(page.slug);
  } else {
    return "/";
  }
};

export const errorFor = (field: string, errors: any) => {
  if (!errors) {
    return null;
  }
  const error = errors.find((e: any) => e.field === field);
  return error ? error.message : null;
};
