import React from "react";
import CollectionPointListItem from "../../atoms/CollectionPointItem/collectionPointItem";
import { getCollectionPointById } from "../../../config/collectionPoints";

const CollectionPointList = () => {
  const collectionPoints = [
    getCollectionPointById("U2hpcHBpbmdNZXRob2Q6Nw=="), // Pleasant Grove
    getCollectionPointById("U2hpcHBpbmdNZXRob2Q6OA=="), // Lindon
  ];

  return (
    <section className="promo promo--nobd">
      <header className="container promo__header">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h2 className="promo__title promo__title--wst">
              Where can I collect?
            </h2>
            {/* <h3 className="promo__subtitle">
              Both locations open weekdays from 11 AM to 4:30 PM
            </h3> */}
          </div>
        </div>
      </header>
      <div className="container promo__body">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="promo__list">
              {collectionPoints.map((collectPoint, i) => (
                <CollectionPointListItem
                  collectionPoint={collectPoint}
                  key={i}
                ></CollectionPointListItem>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CollectionPointList;
