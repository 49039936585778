import React from "react";
import { useQuery } from "@apollo/react-hooks";
import SearchListItem from "./quickSearchListItem";
import { searchProductsQuery } from "../../../views/Search/queries";
import { generateProductUrl } from "../../../core/utils";
import ImagePlaceholder from "../../../assets/images/product-awaiting-image.png";

const QuickSearchList = ({ query }) => {
  const { loading, data, error } = useQuery(searchProductsQuery, {
    variables: { query: query, pageSize: 10 },
  });
  if (loading || error) return null;

  const products = data.products;

  const getProductThumbnail = (searchResult) => {
    return searchResult.node.thumbnail
      ? searchResult.node.thumbnail.url
      : ImagePlaceholder;
  };

  return (
    <section className="searchlist">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <div className="searchlist__list">
            {products.edges.map((searchResult, i) => (
              <SearchListItem
                imageURL={getProductThumbnail(searchResult)}
                imageAlt={searchResult.node.name}
                productLink={generateProductUrl(
                  searchResult.node.id,
                  searchResult.node.name
                )}
                title={searchResult.node.name}
                subTitle={searchResult.node.subtitle}
                price={searchResult.node.pricing.priceRange.start.gross.amount}
                key={i}
              ></SearchListItem>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuickSearchList;
