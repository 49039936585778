import gql from "graphql-tag";

export const userFragment = gql`
  fragment User on User {
    id
    email
    firstName
    lastName
    isStaff
    points
    orderAllowance
  }
`;

export const getUserDetailsQuery = gql`
  ${userFragment}
  query UserDetails {
    me {
      ...User
    }
  }
`;

export const getUserDetailsWithOrdersQuery = gql`
  ${userFragment}
  query OrdersByUser($perPage: Int!, $after: String) {
    me {
      ...User
      orders(first: $perPage, after: $after) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            token
            number
            statusDisplay
            printedAt
            pickedUpAt
            created
            total {
              gross {
                amount
                currency
                localized
              }
            }
            lines {
              id
              productName
              thumbnail {
                alt
                url
              }
            }
          }
        }
      }
    }
  }
`;
