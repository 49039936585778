import gql from "graphql-tag";

export const basicCategoryFragment = gql`
  fragment BasicCategoryFields on Category {
    seoDescription
    seoTitle
    id
    name
    backgroundImage {
      url
    }
  }
`;

export const categoryQuery = gql`
  ${basicCategoryFragment}
  query Category($id: ID!) {
    category(id: $id) {
      ...BasicCategoryFields
      children(first: 20) {
        edges {
          node {
            ...BasicCategoryFields
            products {
              totalCount
            }
          }
        }
      }
    }
  }
`;
