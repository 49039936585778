import { LoginCallback } from "@okta/okta-react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import BagProvider from "./components/BagProvider";
import SecureRoute from "./components/SecureRoute";
import ShopProvider from "./components/ShopProvider";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import AlertProvider from "./core/providers/AlertProvider";
import ApolloProvider from "./core/providers/ApolloProvider";
import { AuthProvider } from "./core/providers/AuthProvider";
import Account from "./views/Account";
import Bag from "./views/Bag";
import Category from "./views/Category";
import Checkout from "./views/Checkout";
import Home from "./views/Home";
import Login from "./views/Login";
import Logout from "./views/Logout";
import NotAuthenticated from "./views/NotAuthenticated";
import NotFound from "./views/NotFound";
import OrderConfirmation from "./views/OrderConfirmation";
import Page from "./views/Page";
import PasswordReset from "./views/PasswordReset";
import Product from "./views/Product";
import RequestPasswordReset from "./views/RequestPasswordReset";
import Search from "./views/Search";

function App() {
  return (
    <AuthProvider>
      <ApolloProvider>
        <AlertProvider>
          <ShopProvider>
            <BagProvider>
              <BrowserRouter>
                <Switch>
                  <Route path="/auth/callback" component={LoginCallback} />

                  <UnauthenticatedRoute exact path="/login" component={Login} />
                  <Route exact path="/logout" component={Logout} />
                  <UnauthenticatedRoute
                    exact
                    path="/request-password-reset"
                    component={RequestPasswordReset}
                  />
                  <UnauthenticatedRoute
                    exact
                    path="/reset-password"
                    component={PasswordReset}
                  />
                  <UnauthenticatedRoute
                    exact
                    path="/not-authenticated"
                    component={NotAuthenticated}
                  />

                  <SecureRoute exact path="/" component={Home} />
                  <SecureRoute path="/account" component={Account} />
                  <SecureRoute path="/bag" component={Bag} />
                  <SecureRoute path="/checkout" component={Checkout} />
                  <SecureRoute path="/search" component={Search} />
                  <SecureRoute
                    path="/product/:slug([a-z-0-9]+)/:id([0-9]+)/"
                    component={Product}
                  />
                  <SecureRoute
                    path="/category/:slug([a-z-0-9]+)/:id([0-9]+)/"
                    component={Category}
                  />
                  <SecureRoute
                    path="/order-confirmation/:id/"
                    component={OrderConfirmation}
                  />
                  <SecureRoute path="/page/:slug/" component={Page} />

                  <Route component={NotFound} />
                </Switch>
              </BrowserRouter>
            </BagProvider>
          </ShopProvider>
        </AlertProvider>
      </ApolloProvider>
    </AuthProvider>
  );
}

export default App;
