import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import Layout from "../../components/Layout";
import useQueryParam from "../../core/hooks/useQueryParam";
import SearchList from "../../components/molecules/Search/searchList";
import { sortOptions } from "../../components/ProductCategory/config";
import WindowTitle from "../../components/WindowTitle";
import crossIcon from "../../assets/icons/icon-cross.svg";
import "./search.scss";

const View = () => {
  const [query] = useQueryParam("q", "");
  const [searchTerm, setSearchTerm] = useState(query);
  const [searchCount, setSearchCount] = useState(0);
  const [selectedSortOption, setSelectedSortOption] = useState("aToZ");

  const clearSearch = () => {
    setSearchTerm("");
    setSearchCount(0);
  };

  useEffect(() => {
    if (!searchTerm) {
      clearSearch();
    }
  }, [searchTerm]);

  return (
    <Layout>
      <WindowTitle title={'Search results for "' + searchTerm + '"'} />
      <section className="display">
        <header className="container">
          <div className="search__header">
            <label htmlFor="searchBox" className="search__label">
              Search term
            </label>

            <div id="searchBox" className="search__searchholder">
              <input
                className="search__input"
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
              ></input>
              {searchTerm.length > 0 && (
                <button
                  className="search__clear"
                  title="Clear Search"
                  onClick={clearSearch}
                >
                  <ReactSVG
                    className="search__clear-icon"
                    src={crossIcon}
                    wrapper="span"
                  />
                </button>
              )}
            </div>
            <div className="search__tools">
              <div className="search__count">
                Products found:{" "}
                <span className="search__countNum">{searchCount}</span>
              </div>
              <div className="search__sort">
                Sort by:
                <select
                  className="search__sort-select"
                  value={selectedSortOption}
                  onChange={(e) => setSelectedSortOption(e.target.value)}
                >
                  {Object.entries(sortOptions).map(([id, option]) => (
                    <option key={id} value={id}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </header>

        {searchTerm && (
          <>
            <div className="search__disclaimer">
              Only available products will be visible in the Employee Store.
              Inventory is updated daily.
            </div>
            <SearchList
              query={searchTerm}
              sortOrder={selectedSortOption}
              setTotalCount={setSearchCount}
            />
          </>
        )}
      </section>
    </Layout>
  );
};
export default View;
