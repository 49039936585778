import "./howItWorks.scss";

const HowItWorksItem = (props) => {
  const { imageURL, imageAlt, content } = props;
  return (
    <div className="howitworksitem">
      <div className="howitworksitem__media">
        <img src={imageURL} alt={imageAlt}></img>
      </div>
      <div className="howitworksitem__content">{content}</div>
    </div>
  );
};
export default HowItWorksItem;
