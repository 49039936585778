import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { productDetailsQuery } from "./queries";
import { getGraphqlIdFromDBId } from "../../core/utils";
import { ReactSVG } from "react-svg";
import RichTextContent from "../../components/RichTextContent";
import AddToCartButton from "../../components/AddToCartButton";
import ProductVariantSwitcher from "../../components/ProductVariantSwitcher";
import Layout from "../../components/Layout";
import bagIcon from "../../assets/icons/icon-bag-primary.svg";
import clockIcon from "../../assets/icons/icon-clock-primary.svg";
import Loader from "../../components/Loader";
import NotFound from "../NotFound";
import ImagePlaceholder from "../../assets/images/product-awaiting-image.png";
import Breadcrumbs from "../../components/Breadcrumbs";
import WindowTitle from "../../components/WindowTitle";
// import RelatedProducts from "../../components/RelatedProducts";

const View = ({ match }) => {
  const id = getGraphqlIdFromDBId(match.params.id, "Product");
  const { loading, data, error } = useQuery(productDetailsQuery, {
    fetchPolicy: "no-cache",
    variables: { id: id },
  });
  const [variants, setVariants] = useState([]);
  const [currentVariant, setCurrentVariant] = useState(null);

  useEffect(() => {
    if (data && data.product) {
      setCurrentVariant(data.product.variants[0]);
      setVariants(data.product.variants);
    }
  }, [data]);

  if (loading || error) return <Loader />;
  if (!data || !data.product || variants.length === 0) return <NotFound />;

  const hasMultipleVariants = () => {
    return variants.length > 1;
  };

  const hasLowStock = () => {
    return (
      currentVariant.stockQuantity > 0 && currentVariant.stockQuantity < 15
    );
  };

  const getPrice = () => {
    return currentVariant.pricing.price.gross.amount;
  };

  const getProductThumbnail = () => {
    return data.product.images[0]
      ? data.product.images[0].url
      : ImagePlaceholder;
  };

  return (
    <Layout>
      <WindowTitle title={data.product.name} />
      <Breadcrumbs data={data.product} type="PRODUCT" />
      <div className="product-detail">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="product-detail__image">
                <img src={getProductThumbnail()} alt={data.product.name}></img>
              </div>
            </div>
            <div className="col-md-6">
              <h1 className="product-detail__heading">{data.product.name}</h1>
              <h2 className="product-detail__sub-heading">
                {data.product.subtitle}
              </h2>
              <div className="product-detail__description">
                <RichTextContent
                  descriptionJson={data.product.descriptionJson}
                />
              </div>

              <div className="product-detail__price">{getPrice()} points</div>

              {hasMultipleVariants() ? (
                <ProductVariantSwitcher
                  variants={variants}
                  currentVariant={currentVariant}
                  setCurrentVariant={setCurrentVariant}
                />
              ) : (
                ""
              )}

              <AddToCartButton
                variant={currentVariant}
                buttonClass="product-detail__btn"
              ></AddToCartButton>

              <div className="product-detail__message">
                <ReactSVG
                  src={bagIcon}
                  wrapper="div"
                  className="product-detail__message-icon"
                />
                <div className="product-detail__message-text">
                  Any products added to your bag will only be guaranteed for 15
                  minutes.
                </div>
              </div>
              {hasLowStock() ? (
                <div className="product-detail__message">
                  <ReactSVG
                    src={clockIcon}
                    wrapper="div"
                    className="product-detail__message-icon"
                  />
                  <div className="product-detail__message-text">
                    Low in Stock. If this purchase is not completed within the
                    allotted time, it will be made available for others to
                    purchase.
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {/* <RelatedProducts productId={data.product.id} /> */}
      </div>
    </Layout>
  );
};
export default View;
