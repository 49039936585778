const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || "0oa13y9ssmAHp18iO5d6";
const ISSUER =
  process.env.REACT_APP_ISSUER || "https://dev-9569140.okta.com/oauth2/default";
const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.REACT_APP_OKTA_TESTING_DISABLEHTTPSCHECK || true;

const CALLBACK_URL =
  process.env.REACT_APP_CALLBACK_URL ||
  "http://localhost:3000/auth/callback/test";
const MESSAGES_URL =
  process.env.REACT_APP_MESSAGES_URL || "http://localhost:3000/api/messages";

const config = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: CALLBACK_URL,
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  resourceServer: {
    messagesUrl: MESSAGES_URL,
  },
};
export default config;
