import { ReactSVG } from "react-svg";
import alertDangerIcon from "../../assets/icons/icon-alert-danger.svg";
import "./styles.scss";

type AlertInlineProps = {
  level: "success" | "error" | "warning";
  description: string;
  className?: string;
};

const AlertInline: React.FC<AlertInlineProps> = ({
  level,
  description,
  className = "",
}) => {
  return (
    <div className={className + " alert-inline alert-inline--" + level}>
      <ReactSVG
        src={alertDangerIcon}
        wrapper="div"
        className="alert-inline__icon"
      />
      <div
        className="alert-inline__message"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
};

export default AlertInline;
