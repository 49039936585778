import { useOktaAuth } from "@okta/okta-react";
import { useContext } from "react";
import { AuthContext } from "../providers/AuthProvider";

export const useAuth = () => {
  const { token, setToken, loginViaOkta, logout, redirectToLogout } =
    useContext(AuthContext);
  const { authState } = useOktaAuth();

  return {
    authToken: token,
    isAuthenticated: token !== undefined,
    setToken,
    loginViaOkta: () => {
      if (authState) {
        loginViaOkta(authState);
      }
    },
    redirectToLogout,
    logout,
  };
};
