import React, { useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import ProductList from "../../ProductList";
import { searchProductsQuery } from "../../../views/Search/queries";
import { sortOptions } from "../../ProductCategory/config";
import { useState } from "reactn";

const SearchList = ({ query, sortOrder, setTotalCount }) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { loading, data, error, fetchMore } = useQuery(searchProductsQuery, {
    variables: {
      query: query,
      pageSize: 20,
      sortBy: {
        field: sortOptions[sortOrder].field,
        direction: sortOptions[sortOrder].direction,
      },
    },
  });

  useEffect(() => {
    if (data && data.products) {
      setTotalCount(data.products.totalCount);
    }
  }, [data, setTotalCount]);

  if (loading || error) return null;

  const products = data.products;

  const canLoadMore = () => {
    return products.pageInfo.hasNextPage;
  };

  const loadMore = async () => {
    setIsLoadingMore(true);
    await fetchMore({
      variables: {
        after: data.products.pageInfo.endCursor,
        sortBy: {
          field: sortOptions[sortOrder].field,
          direction: sortOptions[sortOrder].direction,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult, queryVariables }) => {
        return {
          products: {
            ...fetchMoreResult.products,
            edges: [
              ...previousResult.products.edges,
              ...fetchMoreResult.products.edges,
            ],
          },
        };
      },
    });
    setIsLoadingMore(false);
  };

  return (
    <div
      className={
        "display__body " + (isLoadingMore ? "display__body--loading" : "")
      }
    >
      <ProductList products={products} />
      {canLoadMore() && (
        <div className="display__footer">
          <button
            className="btn btn--secondary"
            disabled={isLoadingMore}
            onClick={() => loadMore()}
          >
            {isLoadingMore ? "loading..." : "load more"}
          </button>
        </div>
      )}
    </div>
  );
};

export default SearchList;
