import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  gql,
  useMutation,
} from "@apollo/client";
import OktaAuth, { AuthState } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import React from "react";
import { useHistory } from "react-router-dom";
import config from "../../config";
import useEffectAfterInitialRender from "../hooks/useEffectAfterInitialRender";

type AuthProviderProps = {
  children: React.ReactNode;
};

type AuthContextProps = {
  token: string | undefined;
  setToken: (token: string | undefined) => void;
  loginViaOkta: (state: AuthState) => void;
  logout: () => void;
  redirectToLogout: () => void;
};

export const AuthContext = React.createContext<AuthContextProps>({
  token: undefined,
  setToken: (token: string | undefined) => {},
  loginViaOkta: (state: AuthState) => {},
  logout: () => {},
  redirectToLogout: () => {},
});

export const createToken = gql`
  mutation CreateToken($email: String!, $password: String!) {
    tokenCreate(email: $email, password: $password) {
      token
      user {
        email
      }
      errors {
        field
        message
      }
    }
  }
`;

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const history = useHistory();
  const [token, setToken] = React.useState(
    localStorage.getItem("token") ?? undefined
  );
  const apolloClient = new ApolloClient({
    link: new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    }),
    cache: new InMemoryCache({}),
  });

  const oktaAuth = new OktaAuth(config.oidc);
  const restoreOriginalUri = async (
    oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    safeNavigate(originalUri || "/");
  };

  useEffectAfterInitialRender(() => {
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
  }, [token]);

  const loginViaOkta = (state: AuthState) => {
    if (state.isAuthenticated) {
      if (!called) {
        oktaAuth.getUser().then((user) => {
          createSaleorToken({
            variables: {
              email: user.email,
              password: state.accessToken?.accessToken,
            },
          });
        });
      }
    } else {
      oktaAuth.signInWithRedirect();
    }
  };

  const [createSaleorToken, { called }] = useMutation(createToken, {
    client: apolloClient,
    onError: (error) => {
      console.error({ error });
      notAuthenticated();
    },
    onCompleted: (data) => {
      if (data.tokenCreate.token) {
        setToken(data.tokenCreate.token);
        safeNavigate("/");
      } else {
        notAuthenticated();
      }
    },
  });

  const notAuthenticated = () => {
    safeNavigate("/not-authenticated");
  };

  const logout = () => {
    oktaAuth.tokenManager.clear();
    setToken(undefined);
  };

  const redirectToLogout = () => {
    safeNavigate("/logout");
  };

  const safeNavigate = (to: string) => {
    if (history) {
      history.push(to);
    } else {
      window.location.href = to;
    }
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <AuthContext.Provider
        value={{ token, setToken, loginViaOkta, logout, redirectToLogout }}
      >
        {children}
      </AuthContext.Provider>
    </Security>
  );
};
