import gql from "graphql-tag";

export const shopDetails = gql`
  fragment MenuItem on MenuItem {
    id
    name
    category {
      id
      name
    }
    url
    collection {
      id
      name
    }
    page {
      slug
    }
  }

  query ShopDetails {
    shop {
      status
      statusPageTitle
      statusPageDescription
      navigation {
        main {
          items {
            ...MenuItem
          }
        }
        secondary {
          items {
            ...MenuItem
          }
        }
      }
    }
  }
`;
