import React from "react";

const CollectionPerson = ({
  dedicatedPerson,
  setDedicatedPerson,
  dedicatedPersonActive,
  setDedicatedPersonActive,
  collectionPoint,
}) => {
  const addDedicatedPerson = () => setDedicatedPersonActive(true);

  const removeDedicatedPerson = () => {
    setDedicatedPersonActive(false);
    setDedicatedPerson(null);
  };

  const collectionPointIsLindon = () =>
    collectionPoint?.id === "U2hpcHBpbmdNZXRob2Q6OA==";

  const switchForm = () => {
    if (dedicatedPersonActive) {
      return (
        <>
          <label
            htmlFor="pickup_person"
            className="collection-point__name-label"
          >
            Full Name
          </label>
          <input
            type="text"
            id="pickup_person"
            className="collection-point__name-input"
            defaultValue={dedicatedPerson}
            onChange={(e) => setDedicatedPerson(e.target.value)}
          />
          <div className="collection-point__pickup-message">
            <span className="collection-point__pickup-message-icon">icon</span>
            <div className="collection-point__pickup-message-text">
              {switchCollectionPointMessage()}
            </div>
          </div>
        </>
      );
    }
  };

  const switchCollectionPointMessage = () => {
    if (collectionPointIsLindon()) {
      return "Campus access required to pickup at this location and Photo ID must be brought to pick up";
    }

    return "Photo ID must be brought to pick up";
  };

  const switchRemoveButton = () => {
    if (dedicatedPersonActive) {
      return (
        <button
          className="collection-point__close-btn"
          title="Remove dedicated pickup person"
          onClick={() => removeDedicatedPerson()}
        >
          Remove dedicated pickup person
        </button>
      );
    }
  };

  return (
    <div className="collection-point__person">
      <p className="collection-point__person-text">
        <span className="collection-point__person-you">
          Not going to be you?{" "}
        </span>
        <span
          className="collection-point__person-link"
          onClick={() => addDedicatedPerson()}
        >
          Add a dedicated pickup person
        </span>
        {switchRemoveButton()}
      </p>
      {switchForm()}
    </div>
  );
};

export default CollectionPerson;
