import Alert from "../Alert";
import Footer from "../Footer";
import Header from "../Header";
import "./index.scss";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="layout">
      <div className="layout__content">
        <div className="layout__header">
          <Header />
          <Alert />
        </div>
        {children}
      </div>
      <div className="layout__footer">
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
