import { useQuery } from "@apollo/react-hooks";
import iconBoxOpen from "../../assets/images/icon-box-open.svg";
import iconCoin from "../../assets/images/icon-coin.svg";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import LogoutButton from "../../components/LogoutButton";
import OrderHistoryItem from "../../components/OrderHistoryItem";
import WindowTitle from "../../components/WindowTitle";
import { getUserDetailsWithOrdersQuery } from "./queries";
import { OrdersByUser, OrdersByUserVariables } from "./types/OrdersByUser";

const View = () => {
  const { loading, data, error } = useQuery<
    OrdersByUser,
    OrdersByUserVariables
  >(getUserDetailsWithOrdersQuery, {
    variables: { perPage: 100 },
  });
  if (loading || error) return <Loader />;

  const me = data?.me;
  const orders = me?.orders;

  if (!me || !orders) return <Loader />;

  const getUserName = () => {
    return me.firstName || me.lastName
      ? me.firstName + " " + me.lastName
      : me.email;
  };

  return (
    <Layout>
      <WindowTitle title="My Account" />
      <section className="account">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="page-title page-title--ml">My Account</h1>
            </div>
          </div>
          <div className="row account__body">
            <aside className="col-md-12 col-lg-5 account__aside account-summary">
              <div className="account-summary__header">
                <div className="account-summary__title-wrapper">
                  <div className="account-summary__subtitle">Welcome back,</div>
                  <h2 className="account-summary__title">{getUserName()}</h2>
                </div>
                <LogoutButton />
              </div>
              <hr className="account-summary__divider" />
              <h2 className="account-summary__title">Account Summary</h2>
              <p className="account-summary__intro">
                Use them before you lose them
              </p>

              <div className="account-summary__block">
                <div className="account-summary__block-row">
                  <span className="account-summary__block-row-icon">
                    <img
                      src={iconCoin}
                      alt="Icon - Open Box"
                      className="account-summary__block-row-icon-img"
                    />
                  </span>
                  <span className="account-summary__block-row-text">
                    Monthly Points remaining:{" "}
                  </span>
                  <span className="account-summary__block-row-data">
                    {me.points}
                  </span>
                </div>
                <div className="account-summary__block-row">
                  <span className="account-summary__block-row-icon">
                    <img
                      src={iconBoxOpen}
                      alt="Icon - Open Box"
                      className="account-summary__block-row-icon-img"
                    />
                  </span>
                  <span className="account-summary__block-row-text">
                    Monthly Orders remaining:{" "}
                  </span>
                  <span className="account-summary__block-row-data">
                    {me.orderAllowance}
                  </span>
                </div>
              </div>
            </aside>
            <div className="col-md-12 col-lg-7 account__history">
              <h2 className="account__title account__title--offset-top-neg">
                Order History
              </h2>
              <div className="account__history-body">
                {orders?.edges.map((order, index) => (
                  <OrderHistoryItem
                    order={order}
                    key={order.node.id}
                    index={index}
                  ></OrderHistoryItem>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default View;
