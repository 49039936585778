import React from "react";
import { ReactSVG } from "react-svg";
import useAlert from "../../core/hooks/useAlert";
import alertDangerIcon from "../../assets/icons/icon-alert-danger.svg";
import alertWarningIcon from "../../assets/icons/icon-alert-warning.svg";

import "./index.scss";

const Alert = () => {
  const { alert } = useAlert();

  let alertIcon;

  if (alert && alert.status === "danger") {
    alertIcon = alertDangerIcon;
  } else if (alert && alert.status === "warning") {
    alertIcon = alertWarningIcon;
  }

  return (
    alert &&
    alert.message && (
      <div className={"alert alert--" + alert.status}>
        <ReactSVG src={alertIcon} wrapper="div" className="alert__icon" />
        <div
          className="alert__message"
          dangerouslySetInnerHTML={{ __html: alert.message }}
        />
      </div>
    )
  );
};
export default Alert;
