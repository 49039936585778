import { useQuery } from "@apollo/react-hooks";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGlobal } from "reactn";
import loaderImage from "../../assets/images/loader.gif";
import logo from "../../assets/images/logo.svg";
import { useAuth } from "../../core/hooks/useAuth";
import MainMenu from "../MainMenu";
import QuickSearch from "../molecules/QuickSearch/quickSearch";
import { userPointsDetails } from "./queries";

const Header = () => {
  const { isAuthenticated } = useAuth();
  const [mobileMenuOpen, setMobileMenuOpen] = useGlobal("mobileMenuOpen");
  const { data: pointsSummaryData, refetch: refetchPointsSummary } = useQuery(
    userPointsDetails,
    { fetchPolicy: "no-cache", skip: !isAuthenticated }
  );
  const [pointsAvailable, setPointsAvailable] = useState(0);
  const [pointsInBag, setPointsInBag] = useGlobal("pointsInBag");
  const [itemsInBag, setItemsInBag] = useGlobal("itemsInBag");
  const [isUpdatingPointsSummary, setIsUpdatingPointsSummary] = useState(false);
  const [quickSearchOpen, setQuickSearchOpen] = useGlobal("quickSearchOpen");

  const handleMenuClick = () => {
    const menuOpen = !mobileMenuOpen;
    if (menuOpen) {
      updatePointsSummary();
    }
    setMobileMenuOpen(menuOpen);
  };

  const handleSearchClick = () => {
    setQuickSearchOpen(!quickSearchOpen);
  };

  const pointsRemaining = () => {
    return pointsAvailable - pointsInBag;
  };

  const updatePointsSummary = async () => {
    if (isUpdatingPointsSummary) return;

    setIsUpdatingPointsSummary(true);
    await refetchPointsSummary();
    setIsUpdatingPointsSummary(false);
  };

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.classList.add("mobile-menu-active");
    } else {
      if (document.body.classList.contains("mobile-menu-active")) {
        document.body.classList.remove("mobile-menu-active");
      }
    }
  }, [mobileMenuOpen]);

  useEffect(() => {
    if (quickSearchOpen) {
      document.body.classList.add("quick-search-active");
      // @ts-ignore
      document.querySelector("#quicksearch_input").focus();
    } else {
      if (document.body.classList.contains("quick-search-active")) {
        document.body.classList.remove("quick-search-active");
      }
    }
  }, [quickSearchOpen]);

  useEffect(() => {
    if (pointsSummaryData) {
      setPointsAvailable(pointsSummaryData.me.points);
      if (pointsSummaryData.me.checkout) {
        setPointsInBag(pointsSummaryData.me.checkout.totalPrice.net.amount);
        setItemsInBag(pointsSummaryData.me.checkout.lines.length);
      }
    }
  }, [pointsSummaryData, setItemsInBag, setPointsInBag]);

  const pointsSummary = () => {
    if (isUpdatingPointsSummary) {
      return (
        <>
          <div className="header__points-summary-title">Points summary</div>
          <div className="header__points-summary-loading">
            <img
              className="header__points-summary-loading-spinner"
              src={loaderImage}
              alt=""
            />
          </div>
        </>
      );
    }

    return (
      <>
        <div className="header__points-summary-title">Points summary</div>
        <div className="header__points-summary-item">
          <span>Available:</span>
          <span className="header__points-summary-value">
            {pointsAvailable}
          </span>
        </div>
        <div className="header__points-summary-item">
          <span>In my bag:</span>
          <span className="header__points-summary-value">{pointsInBag}</span>
        </div>
        <div className="header__points-summary-item">
          <span>Remaining:</span>
          <span className="header__points-summary-value">
            {pointsRemaining()}
          </span>
        </div>
      </>
    );
  };

  return (
    <header className="header">
      <div className="header__logo">
        <Link to="/">
          <img
            className="header__logo-image"
            src={logo}
            alt="doTERRA Employee Store"
          />
        </Link>
      </div>
      <div className="header__nav">
        <MainMenu
          pointsAvailable={pointsAvailable}
          pointsInBag={pointsInBag}
          pointsRemaining={pointsRemaining()}
          isUpdatingPointsSummary={isUpdatingPointsSummary}
        />
      </div>
      {isAuthenticated && (
        <>
          <nav className="nav nav--toolbar header__toolbar">
            <ul className="nav__menu">
              <li className="nav__item nav__item--search">
                <button className="nav__link" onClick={handleSearchClick}>
                  <span className="nav__link-text">Search</span>
                </button>
              </li>
              <li className="nav__item nav__item--account">
                <Link className="nav__link" to="/account">
                  <span className="nav__link-text">Account</span>
                </Link>
              </li>
              <li
                className="nav__item nav__item--points"
                onMouseEnter={() => {
                  updatePointsSummary();
                }}
              >
                <Link className="nav__link" to="/account">
                  <span className="nav__link-text">
                    Points:{" "}
                    <strong>
                      {pointsAvailable - pointsInBag >= 0
                        ? pointsAvailable - pointsInBag
                        : "-"}
                    </strong>
                  </span>
                </Link>
                <div className="header__points-summary">{pointsSummary()}</div>
              </li>
              <li className="nav__item nav__item--bag">
                <Link className="nav__link" to="/bag">
                  <span className="nav__item__bag-count">
                    {itemsInBag > 0 ? itemsInBag : ""}
                  </span>
                  <span className="nav__link-text">Bag</span>
                </Link>
              </li>
              <li className="nav__item nav__item--mobile">
                <button className="nav__link" onClick={handleMenuClick}>
                  <span className="nav__link-text">Mobile</span>
                </button>
              </li>
            </ul>
          </nav>

          <QuickSearch />
        </>
      )}
    </header>
  );
};

export default Header;
