import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { getPage } from "./queries";
import RichTextContent from "../../components/RichTextContent";
import Layout from "../../components/Layout";
import NotFound from "../NotFound";
import Loader from "../../components/Loader";
import WindowTitle from "../../components/WindowTitle";

const View = ({ match }) => {
  const { loading, data, error } = useQuery(getPage, {
    variables: { slug: match.params.slug },
  });

  if (loading) return <Loader />;
  if (error || !data.page) return <NotFound />;

  const page = data.page;

  return (
    <Layout>
      <WindowTitle title={page.title} />
      <div className="page">
        <section className="page__inner">
          <div className="container page__header">
            <div className="row">
              <div className="col-12">
                <h1 className="page__title">{page.title}</h1>
                <p className="page__intro">
                  Questions answers and useful information
                </p>
              </div>
            </div>
          </div>
          <div className="container page__main">
            <div className="row">
              <div className="col-md-7 page__content faqs">
                <RichTextContent descriptionJson={page.contentJson} />
              </div>
              <aside className="col-md-5 col-lg-4 offset-lg-1 page__sidebar">
                <h2>Still got questions?</h2>
                <p>
                  <strong>Email:</strong> employeestore@doterra.com
                </p>
              </aside>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};
export default View;
