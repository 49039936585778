import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import WindowTitle from "../../components/WindowTitle";
import { useAuth } from "../../core/hooks/useAuth";
import "./styles.scss";

const View = () => {
  const { logout } = useAuth();
  logout();

  return (
    <Layout>
      <WindowTitle title="Successfully logged out" />
      <div className="logout">
        <section className="display">
          <header className="container display__header">
            <h1 className="display__heading">Successfully logged out</h1>
            <p className="text-center">
              You have successfully logged out.{" "}
              <Link to="/">Return to the homepage</Link>
            </p>
          </header>
        </section>
      </div>
    </Layout>
  );
};

export default View;
