import { useMutation, useQuery } from "@apollo/react-hooks";
import { useEffect, useRef, useState } from "react";
import useAlert from "../../core/hooks/useAlert";
import { useAuth } from "../../core/hooks/useAuth";
import { checkoutCustomerDetach } from "../../views/Checkout/queries";
import { BagContext } from "./context";
import { userCheckoutSummary } from "./queries";

const BagProvider = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { loading, data, error } = useQuery(userCheckoutSummary, {
    skip: !isAuthenticated,
  });
  const [checkoutCustomerDetachMutation] = useMutation(checkoutCustomerDetach);
  const [bag] = useState(null);
  const { addAlert, removeAlert } = useAlert();
  const initialMinute = 10;
  const initialSeconds = 50;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  const bagIsActive = useRef(false);

  useEffect(() => {
    if (data) {
      if (!data.me.checkout) {
        removeAlert();
        return;
      }

      if (data.me.checkout.lines.length === 0) {
        removeAlert();
        checkoutCustomerDetachMutation({
          variables: {
            checkoutId: data.me.checkout.id,
          },
        });
        window.location = "/bag";
        return;
      }
      // get the time difference between the current time and the last time
      // time the bag was created.  is the bag is older that 15 mins the bag is expired
      const bagCreated = new Date(data.me.checkout.created).toISOString();
      const now = new Date().toISOString();
      var diff =
        (new Date(bagCreated).getTime() - new Date(now).getTime()) / 1000;
      diff /= 60;
      const diffMins = Math.abs(Math.round(diff));
      bagIsActive.current = diffMins <= 15;

      var delta =
        Math.abs(new Date(bagCreated).getTime() - new Date(now).getTime()) /
        1000;
      var diffSecs = Math.abs(Math.round(delta % 60));

      // 15 min is the bag expiry time
      setMinutes(15 - diffMins);
      setSeconds(60 - diffSecs);
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          if (seconds === 60) {
            setSeconds(0);
          } else {
            setSeconds(seconds - 1);
          }
        }
        if (seconds <= 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
            removeAlert();
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);

      if (bagIsActive.current) {
        addAlert(
          `Products may become available for others to purchase if you do not complete check out in ${minutes} mins ${seconds} seconds`,
          "warning"
        );
      } else {
        clearInterval(myInterval);
        removeAlert();
      }
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [
    addAlert,
    removeAlert,
    checkoutCustomerDetachMutation,
    data,
    minutes,
    seconds,
  ]);

  if (loading || error) return null;
  return (
    <BagContext.Provider value={{ bag, bagIsActive }}>
      {children}
    </BagContext.Provider>
  );
};

export default BagProvider;
