import { useContext } from "react";
import logoSmall from "../../assets/images/logo-small.svg";
import { getNavLinkUrl } from "../../core/utils";
import { ShopContext } from "../ShopProvider/context";

const Footer = () => {
  const { shop } = useContext(ShopContext);
  if (!shop) return null;

  const menu = shop?.navigation?.secondary;

  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__copyright">
          <img
            src={logoSmall}
            alt="doTERRA Employee Store"
            className="footer__logo"
          />{" "}
          dōTERRA © {new Date().getFullYear()}
        </div>
        <nav className="footer__nav" role="navigation">
          {menu?.items &&
            menu?.items.map((item, i) => (
              <a
                className="footer__nav-link"
                href={getNavLinkUrl(item)}
                key={i}
              >
                {item?.name}
              </a>
            ))}
          <a
            href="mailto:employeestore@doterra.com"
            className="footer__nav-link"
          >
            Contact Us - employeestore@doterra.com
          </a>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
