import React, { useState, useCallback } from "react";

export const AlertContext = React.createContext({
  alert: null,
  addAlert: () => {},
  removeAlert: () => {},
});

export default function AlertProvider({ children }) {
  const [alert, setAlert] = useState(null);

  const removeAlert = () => setAlert(null);

  const addAlert = (message, status) => setAlert({ message, status });

  const contextValue = {
    alert,
    addAlert: useCallback((message, status) => addAlert(message, status), []),
    removeAlert: useCallback(() => removeAlert(), []),
  };

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
    </AlertContext.Provider>
  );
}
