import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { categoryQuery } from "./queries";
import { getGraphqlIdFromDBId } from "../../core/utils";
import ProductCategory from "../../components/ProductCategory";
import CategoryList from "../../components/CategoryList";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import NotFound from "../NotFound";
import WindowTitle from "../../components/WindowTitle";

const View = ({ match }) => {
  const id = getGraphqlIdFromDBId(match.params.id, "Category");
  const { loading, data, error } = useQuery(categoryQuery, {
    variables: { id: id, pageSize: 10 },
  });

  if (loading) return <Loader />;
  if (!data || error) return <NotFound />;

  const { category } = data;

  const switchDisplayType = () => {
    if (category.children.edges.length) {
      return (
        <CategoryList
          category={category}
          categories={category.children.edges}
        />
      );
    }
    return <ProductCategory category={category} />;
  };

  return (
    <Layout>
      <WindowTitle title={category.name}></WindowTitle>
      {switchDisplayType()}
    </Layout>
  );
};
export default View;
