import React from "react";
import BackgroundImage from "../../assets/images/home-feature-1.jpg";

const Banner = () => {
  return (
    <section
      className="banner"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 banner__inner">
            <h2 className="banner__heading">Amazing products &amp; offers</h2>
            <h3 className="banner__sub-heading">
              Just for you, our amazing team.
            </h3>
            <span className="banner__overlay"></span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
