import gql from "graphql-tag";

export const priceFragment = gql`
  fragment Price on TaxedMoney {
    gross {
      amount
      currency
      localized
    }
    net {
      amount
      currency
      localized
    }
  }
`;

export const basicProductFragment = gql`
  fragment BasicProductFields on Product {
    id
    name
    subtitle
    isAvailable
    thumbnail {
      url
      alt
    }
    thumbnail2x: thumbnail(size: 510) {
      url
    }
  }
`;

export const productPricingFragment = gql`
  ${priceFragment}
  fragment ProductPricingField on Product {
    pricing {
      onSale
      priceRangeUndiscounted {
        start {
          ...Price
        }
        stop {
          ...Price
        }
      }
      priceRange {
        start {
          ...Price
        }
        stop {
          ...Price
        }
      }
    }
  }
`;

export const selectedAttributeFragment = gql`
  fragment SelectedAttributeFields on SelectedAttribute {
    attribute {
      id
      name
    }
    values {
      id
      name
    }
  }
`;

export const productVariantFragment = gql`
  ${priceFragment}
  fragment ProductVariantFields on ProductVariant {
    id
    sku
    name
    stockQuantity
    isAvailable
    quanityAllocatedInCheckout
    images {
      id
      url
      alt
    }
    pricing {
      onSale
      priceUndiscounted {
        ...Price
      }
      price {
        ...Price
      }
    }
    attributes {
      attribute {
        id
        name
      }
      values {
        id
        name
        value: name
      }
    }
  }
`;

export const productDetailsQuery = gql`
  ${basicProductFragment}
  ${productVariantFragment}
  ${productPricingFragment}
  query ProductDetails($id: ID!) {
    product(id: $id) {
      ...BasicProductFields
      ...ProductPricingField
      descriptionJson
      category {
        id
        name
      }
      images {
        id
        url
      }
      variants {
        ...ProductVariantFields
      }
      seoDescription
      seoTitle
      isAvailable
    }
  }
`;

// FIXME: Check how to handle pagination of `productVariants` in the UI.
// We need allow the user view  all cart items regardless of pagination.
export const productVariantsQuery = gql`
  ${basicProductFragment}
  ${selectedAttributeFragment}
  ${productVariantFragment}
  query VariantList($ids: [ID!]) {
    productVariants(ids: $ids, first: 100) {
      edges {
        node {
          ...ProductVariantFields
          stockQuantity
          product {
            ...BasicProductFields
            attributes {
              ...SelectedAttributeFields
            }
          }
        }
      }
    }
  }
`;

export const productUpsellQuery = gql`
  ${basicProductFragment}
  ${productVariantFragment}
  ${productPricingFragment}
  query UpsellProducts($ids: [ID!], $pointsRemaining: Float!) {
    products(
      first: 1
      filter: {
        price: { lte: $pointsRemaining }
        excludeIds: $ids
        stockAvailability: IN_STOCK
        isPublished: true
      }
      sortBy: { field: PRICE, direction: DESC }
    ) {
      edges {
        node {
          ...BasicProductFields
          ...ProductPricingField
          variants {
            ...ProductVariantFields
            stockQuantity
          }
        }
      }
    }
  }
`;

export const productRelatedProductsQuery = gql`
  ${basicProductFragment}
  ${productPricingFragment}
  ${productVariantFragment}
  query ProductRelatedProducts($id: ID!) {
    product(id: $id) {
      relatedProducts {
        ...BasicProductFields
        ...ProductPricingField
        variants {
          ...ProductVariantFields
        }
      }
    }
  }
`;
