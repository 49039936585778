import { useQuery } from "@apollo/react-hooks";
import { useAuth } from "../../core/hooks/useAuth";
import SiteStatus from "../../views/SiteStatus";
import Loader from "../Loader";
import { ShopContext } from "./context";
import { shopDetails } from "./queries";
import { ShopDetails } from "./types/ShopDetails";

type ShopProviderProps = {
  children: React.ReactNode;
};

const ShopProvider: React.FC<ShopProviderProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { loading, data, error } = useQuery<ShopDetails>(shopDetails, {
    skip: !isAuthenticated,
  });

  if (loading || error) return <Loader />;

  const shopIsClosed = () => data?.shop?.status === "closed";

  if (shopIsClosed()) {
    return (
      <SiteStatus
        status={data?.shop?.status}
        title={data?.shop?.statusPageTitle}
        description={data?.shop?.statusPageDescription}
      />
    );
  }

  return (
    <ShopContext.Provider value={data ?? { shop: null }}>
      {children}
    </ShopContext.Provider>
  );
};

export default ShopProvider;
