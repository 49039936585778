import React from "react";
import ProductListItem from "../ProductListItem";

const ProductList = ({ products }) => {
  if (products.edges) {
    products = products.edges.map((e) => e.node);
  }

  return (
    <section className="display__grid display__grid--4md">
      {products.map((product) => (
        <ProductListItem product={product} key={product.id}></ProductListItem>
      ))}
    </section>
  );
};

export default ProductList;
