import React from "react";

import "./index.scss";

const Hero = ({
  children,
  imageUrl,
  buttonText,
  buttonUrl,
  displayProductOfTheMonth,
}) => {
  const hasButton = () => buttonText && buttonUrl;

  return (
    <section
      className="hero hero--top-left"
      style={{
        backgroundImage: `url(${imageUrl})`,
      }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 inherit-pos">
            <div className="hero__inner-wrap">
              <div className="hero__inner">
                {displayProductOfTheMonth && (
                  <div className="hero__ribbon">Product of the month</div>
                )}
                {children}
                {hasButton() && (
                  <div className="hero__actions">
                    <a
                      className="btn btn-primary btn-size-lg"
                      target="_blank"
                      rel="noreferrer"
                      href={buttonUrl}
                    >
                      {buttonText}
                    </a>
                  </div>
                )}
              </div>
              <span className="hero__overlay"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
