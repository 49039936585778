import { useAuth } from "../../core/hooks/useAuth";
import "./index.scss";

const LogoutButton = () => {
  const { redirectToLogout } = useAuth();

  return (
    <button
      className="logout-button"
      onClick={() => {
        redirectToLogout();
      }}
    >
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.28995 1.71042C9.28995 1.22944 8.9 0.839493 8.41901 0.839493H0.870932C0.389945 0.839493 0 1.22944 0 1.71042V19.1291C0 19.6101 0.389945 20 0.870932 20H8.41901C8.9 20 9.28995 19.6101 9.28995 19.1291C9.28995 18.6481 8.9 18.2581 8.41901 18.2581H1.74186V2.58136H8.41901C8.9 2.58136 9.28995 2.19141 9.28995 1.71042ZM12.4492 15.1001C12.7893 15.4402 13.3409 15.4402 13.6809 15.1001L17.7451 11.0356C18.0852 10.6955 18.0852 10.144 17.7451 9.80394L13.6809 5.73971C13.5109 5.56964 13.288 5.48464 13.0651 5.48464C12.8422 5.48464 12.6193 5.56964 12.4492 5.73971C12.1091 6.07983 12.1091 6.63125 12.4492 6.97138L15.0267 9.54887H4.35576C3.87477 9.54887 3.48482 9.93882 3.48482 10.4198C3.48482 10.9008 3.87477 11.2907 4.35576 11.2907H15.0268L12.4492 13.8685C12.1091 14.2086 12.1091 14.76 12.4492 15.1001Z"
          fill="#A25EB5"
        />
      </svg>

      <span>logout</span>
    </button>
  );
};

export default LogoutButton;
