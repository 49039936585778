import React from "react";
import WindowTitle from "../../components/WindowTitle";
import LayoutBlank from "../../components/LayoutBlank";
import logo from "../../assets/images/logo.svg";

const View = ({ status, title, description }) => {
  const defaultTitle = "Site: " + status;

  return (
    <LayoutBlank>
      <WindowTitle title={title || defaultTitle} />
      <div className="site-status">
        <div className="site-status__logo">
          <img
            className="site-status__logo-image"
            src={logo}
            alt="doTERRA Employee Store"
          />
        </div>
        <h1 className="site-status__title">{title || defaultTitle}</h1>
        <p className="site-status__description">{description}</p>
      </div>
    </LayoutBlank>
  );
};

export default View;
