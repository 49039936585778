import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import WindowTitle from "../../components/WindowTitle";
import "./styles.scss";

const View = () => {
  return (
    <Layout>
      <WindowTitle title="Not allowed" />
      <div className="not-authenticated">
        <section className="display">
          <header className="container display__header">
            <h1 className="display__heading">Not allowed</h1>
            <p className="text-center">
              Sorry, you are not allowed to perform that action.{" "}
              <Link to="/">Return to the homepage</Link>
            </p>
          </header>
        </section>
      </div>
    </Layout>
  );
};

export default View;
