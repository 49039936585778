import gql from "graphql-tag";

import {
  basicProductFragment,
  productPricingFragment,
  selectedAttributeFragment,
  productVariantFragment,
} from "../../views/Product/queries";

export const featuredProducts = gql`
  ${basicProductFragment}
  ${productPricingFragment}
  ${selectedAttributeFragment}
  ${productVariantFragment}
  query FeaturedProducts {
    shop {
      homepageCollection {
        id
        products(first: 3) {
          edges {
            node {
              ...BasicProductFields
              ...ProductPricingField
              category {
                id
                name
              }
              attributes {
                ...SelectedAttributeFields
              }
              variants {
                ...ProductVariantFields
              }
            }
          }
        }
      }
    }
  }
`;
