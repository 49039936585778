import React from "react";

const CollectionPointItem = ({ collectionPoint }) => {
  return (
    <div className="promo__list-item cta-card">
      <div className="cta-card__media">
        <img src={collectionPoint.imageURL} alt={collectionPoint.name}></img>
      </div>
      <div className="cta-card__body">
        <h3 className="cta-card__title cta-card__title--sm">
          {collectionPoint.name}
        </h3>
        {collectionPoint.description ? (
          <div
            className="cta-card__content"
            dangerouslySetInnerHTML={{ __html: collectionPoint.description }}
          ></div>
        ) : (
          ""
        )}

        <div className="cta-card__content">
          <strong>{collectionPoint.openingHours}</strong>
        </div>

        {collectionPoint.mapURL ? (
          <a href={collectionPoint.mapURL} className="cta-card__link">
            Find on Google Maps
          </a>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default CollectionPointItem;
