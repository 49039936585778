import gql from "graphql-tag";

import {
  basicProductFragment,
  productPricingFragment,
  productVariantFragment,
} from "../Product/queries";

export const searchProductsQuery = gql`
  ${basicProductFragment}
  ${productPricingFragment}
  ${productVariantFragment}
  query SearchProducts(
    $query: String!
    $attributes: [AttributeInput]
    $pageSize: Int
    $sortBy: ProductOrder
    $after: String
  ) {
    products(
      filter: {
        search: $query
        attributes: $attributes
        stockAvailability: IN_STOCK
      }
      first: $pageSize
      sortBy: $sortBy
      after: $after
    ) {
      totalCount
      edges {
        node {
          ...BasicProductFields
          ...ProductPricingField
          id
          name
          thumbnail {
            url
            alt
          }
          variants {
            ...ProductVariantFields
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
