import gql from "graphql-tag";

export const userPointsDetails = gql`
  query userPointsDetails {
    me {
      id
      points
      checkout {
        totalPrice {
          net {
            amount
          }
        }
        lines {
          quantity
        }
      }
    }
  }
`;
