import { useMutation } from "@apollo/client";
import { useState } from "react";
import { Link } from "react-router-dom";
import AlertInline from "../../components/AlertInline";
import Layout from "../../components/Layout";
import TextInput from "../../components/TextInput";
import WindowTitle from "../../components/WindowTitle";
import { useAuth } from "../../core/hooks/useAuth";
import { createToken } from "../../core/providers/AuthProvider";
import {
  CreateToken,
  CreateTokenVariables,
} from "../../core/providers/types/CreateToken";
import { errorFor } from "../../core/utils";
import "./styles.scss";

const View = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setToken } = useAuth();

  const [createSaleorToken, { data, loading }] = useMutation<
    CreateToken,
    CreateTokenVariables
  >(createToken, {
    onCompleted: (data) => {
      if (data.tokenCreate?.token) {
        setToken(data.tokenCreate.token);
      }
    },
  });

  const generalErrorMessage =
    data?.tokenCreate?.errors?.find((error) => error?.field === null)
      ?.message || null;

  return (
    <Layout>
      <WindowTitle title="Login" />
      <div className="container">
        <div className="login">
          <h1>Login</h1>
          <form
            method="post"
            onSubmit={(e) => {
              e.preventDefault();
              createSaleorToken({
                variables: {
                  email,
                  password,
                },
              });
            }}
            className="form"
          >
            {generalErrorMessage && (
              <div className="form__row">
                <AlertInline level="error" description={generalErrorMessage} />
              </div>
            )}
            <div className="form__row">
              <TextInput
                type="email"
                label="Email address"
                id="email"
                placeholder="john.smith@example.com"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                autoFocus
                required
                error={errorFor("email", data?.tokenCreate?.errors)}
              />
            </div>
            <div className="form__row">
              <TextInput
                type="password"
                id="password"
                label="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                help={
                  <Link to="/request-password-reset">Forgot password?</Link>
                }
                required
                error={errorFor("password", data?.tokenCreate?.errors)}
              />
            </div>
            <div className="login__button">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};
export default View;
