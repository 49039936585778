import {
  ApolloProvider as AP,
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  concat,
} from "@apollo/client";
import { useAuth } from "../hooks/useAuth";

interface AlertProviderProps {
  children: React.ReactNode;
}

const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const { authToken } = useAuth();

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  });

  const cache = new InMemoryCache({});

  const authMiddleware = (authToken?: string) =>
    new ApolloLink((operation, forward) => {
      // add the authorization to the headers
      if (authToken) {
        operation.setContext({
          headers: {
            authorization: `JWT ${authToken}`,
          },
        });
      }

      return forward(operation);
    });

  const apolloClient = new ApolloClient({
    cache,
    link: concat(authMiddleware(authToken), httpLink),
  });

  return <AP client={apolloClient}>{children}</AP>;
};

export default AlertProvider;
