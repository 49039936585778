import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Layout from "../../components/Layout";
import {
  userCheckoutDetails,
  createPayment,
  completeCheckout,
} from "./queries";
import { useHistory } from "react-router-dom";
import useAlert from "../../core/hooks/useAlert";
import Loader from "../../components/Loader";
import WindowTitle from "../../components/WindowTitle";
import AlertInline from "../../components/AlertInline";
import { getCollectionPointById } from "../../config/collectionPoints";

const View = () => {
  const [isPaying, setIsPaying] = useState(false);
  const { loading, data, error } = useQuery(userCheckoutDetails);
  const [createPaymentMutation] = useMutation(createPayment);
  const [completeCheckoutMutation] = useMutation(completeCheckout);
  const { removeAlert } = useAlert();
  const history = useHistory();

  async function pay() {
    setIsPaying(true);
    const createPaymentResult = await createPaymentMutation({
      variables: {
        checkoutId: data.me.checkout.id,
        input: {
          amount: data.me.checkout.subtotalPrice.net.amount,
          gateway: "doTERRAPoints",
          token: data.me.checkout.token,
        },
      },
    });

    const completeCheckoutResult = await completeCheckoutMutation({
      variables: {
        checkoutId: data.me.checkout.id,
      },
    });

    if (
      createPaymentResult.data.checkoutPaymentCreate.errors.length ||
      completeCheckoutResult.data.checkoutComplete.errors.length
    ) {
      console.error({
        createPaymentErrors:
          createPaymentResult.data.checkoutPaymentCreate.errors,
        checkoutCompleteErrors:
          completeCheckoutResult.data.checkoutComplete.errors,
      });
      history.push("/bag");
    } else {
      removeAlert();
      redirectToOrderConfirmation(
        completeCheckoutResult.data.checkoutComplete.order
      );
    }

    setIsPaying(false);
  }

  const redirectToOrderConfirmation = (order) =>
    (document.location.href = `/order-confirmation/${order.id}`);

  const freeGiftCount = () =>
    data.me.checkout.lines.filter((line) => line.variant.isProductOfTheMonth)
      .length;

  const locationImage = getCollectionPointById(
    data.me.checkout.shippingMethod.id
  ).imageURL;

  if (loading) return <Loader />;

  if (error) {
    return <p>Unknown error</p>;
  }

  if (!data.me.checkout) {
    history.push("/bag");
    return null;
  }
  return (
    <Layout>
      <WindowTitle title="Order Summary" />
      <div className="checkout">
        <div className="container">
          <div className="progress-steps__progress">
            <div className="progress-steps__step progress-steps__step--active complete">
              <div className="progress-steps__step-circle"></div>
              <span className="progress-steps__step-title">
                Review &amp; Confirm
              </span>
            </div>

            <div className="progress-steps__step">
              <div className="progress-steps__step-circle"></div>
              <span className="progress-steps__step-title">Complete</span>
            </div>
          </div>

          <div className="checkout__order-summary order-summary order-summary--checkout">
            <h1 className="order-summary__title">Order Summary</h1>
            <div className="order-summary__row">
              <div className="order-summary__row-label">items added</div>
              <div className="order-summary__row-data">
                {data.me.checkout.lines.length - freeGiftCount()}
              </div>
            </div>
            <div className="order-summary__row">
              <div className="order-summary__row-label">free gift</div>
              <div className="order-summary__row-data">{freeGiftCount()}</div>
            </div>

            <div className="bag__order-summary-total order-summary__total">
              <div className="order-summary__total-row">
                <div className="order-summary__total-label">Total items</div>
                <div className="order-summary__total-data">
                  {data.me.checkout.lines.length}
                </div>
              </div>
              <div className="order-summary__total-row">
                <div className="order-summary__total-label">
                  Order total{" "}
                  <span className="order-summary__total-label-note">
                    (Employee points)
                  </span>
                </div>
                <div className="order-summary__total-data order-summary__total-data--points">
                  {data.me.checkout.subtotalPrice.net.amount} pts
                </div>
              </div>
            </div>
            <div className="collection-point">
              <h2 className="order-summary__subtitle collection-point__title">
                Collection point
              </h2>
              <div className="collection-point__name">
                {data.me.checkout.shippingMethod.name}
              </div>
              <div className="collection-point__photo">
                <img
                  src={locationImage}
                  alt={data.me.checkout.shippingMethod.name}
                />
              </div>
            </div>

            <div className="order-summary__actions">
              {data.me.orderAllowance <= 1 && (
                <div className="order-summary__alert">
                  <AlertInline
                    level="warning"
                    description="This is your last order of the month, any unused points will be lost."
                  />
                </div>
              )}
              <button
                className="btn btn--blue order-summary__btn"
                disabled={isPaying}
                onClick={async () => {
                  await pay();
                }}
              >
                {isPaying ? "Paying..." : "Confirm order"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default View;
