import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import ProductList from "../ProductList";
import Loader from "../Loader";
import { categoryProductsQuery } from "./queries";
import Breadcrumbs from "../../components/Breadcrumbs";
import { sortOptions } from "./config";

const ProductCategory = ({ category }) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState("aToZ");
  const { loading, data, error, fetchMore } = useQuery(categoryProductsQuery, {
    variables: {
      categoryId: category.id,
      pageSize: 21,
      sortBy: {
        field: "NAME",
        direction: "ASC",
      },
    },
    fetchPolicy: "cache-first",
  });

  if (error) return null;
  if (loading) return <Loader />;

  const { products } = data;

  const sort = async (sortOptionKey) => {
    setIsLoadingMore(true);
    setSelectedSortOption(sortOptionKey);
    await fetchMore({
      variables: {
        sortBy: {
          field: sortOptions[sortOptionKey].field,
          direction: sortOptions[sortOptionKey].direction,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult, queryVariables }) => {
        return fetchMoreResult;
      },
    });
    setIsLoadingMore(false);
  };

  const canLoadMore = () => {
    return products.pageInfo.hasNextPage;
  };

  const loadMore = async () => {
    setIsLoadingMore(true);
    await fetchMore({
      variables: {
        after: data.products.pageInfo.endCursor,
        sortBy: {
          field: sortOptions[selectedSortOption].field,
          direction: sortOptions[selectedSortOption].direction,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult, queryVariables }) => {
        return {
          products: {
            ...fetchMoreResult.products,
            edges: [
              ...previousResult.products.edges,
              ...fetchMoreResult.products.edges,
            ],
          },
        };
      },
    });
    setIsLoadingMore(false);
  };

  return (
    <section className="display">
      <Breadcrumbs data={category} type="CATEGORY" />
      <header className="container-fluid display__header">
        <div className="row">
          <div className="col-md-12">
            <div className="display__wrapper">
              <h1 className="display__heading">{category.name}</h1>
              <div className="display__form-group">
                <label htmlFor="sort">Show</label>
                <select
                  id="sort"
                  className="display__sort"
                  value={selectedSortOption}
                  onChange={(e) => sort(e.target.value)}
                >
                  {Object.entries(sortOptions).map(([id, option]) => (
                    <option key={id} value={id}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="display__disclaimer">
        Only available products will be visible in the Employee Store. Inventory
        is updated daily.
      </div>
      <div
        className={
          "display__body " + (isLoadingMore ? "display__body--loading" : "")
        }
      >
        <ProductList products={products} />
        {canLoadMore() && (
          <div className="display__footer">
            <button
              className="btn btn--secondary"
              disabled={isLoadingMore}
              onClick={() => loadMore()}
            >
              {isLoadingMore ? "loading..." : "load more"}
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default ProductCategory;
