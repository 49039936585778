import React from "react";

const ProductVariantSwitcher = ({
  variants,
  currentVariant,
  setCurrentVariant,
}) => {
  const className = (variant) => {
    let className = "product-variant-switcher__variant";
    if (currentVariant.id === variant.id) {
      className += " product-variant-switcher__variant--selected";
    }

    return className;
  };

  return (
    <>
      <div className="product-variant-switcher">
        {variants.map((variant) => (
          <button
            className={className(variant)}
            key={variant.id}
            onClick={() => {
              setCurrentVariant(variant);
            }}
          >
            {variant.name}
          </button>
        ))}
      </div>
    </>
  );
};

export default ProductVariantSwitcher;
