import { useMutation, useQuery } from "@apollo/react-hooks";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import AlertInline from "../../components/AlertInline";
import BagAccountSummary from "../../components/BagAccountSummary";
import BagItem from "../../components/BagItem";
import CollectionPerson from "../../components/CollectionPerson";
import CollectionPointPicker from "../../components/CollectionPointPicker";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import ProductUpsell from "../../components/ProductUpsell";
import WindowTitle from "../../components/WindowTitle";
import useAlert from "../../core/hooks/useAlert";
import {
  deleteCheckoutLine,
  updateCheckoutMetadata,
  userCheckoutDetails,
} from "../Checkout/queries";

const View = () => {
  const {
    loading,
    data,
    error,
    refetch: refetchCheckoutData,
  } = useQuery(userCheckoutDetails);
  const [deleteCheckoutLineMutation] = useMutation(deleteCheckoutLine);
  const [updateCheckoutMetadataMutation] = useMutation(updateCheckoutMetadata);
  const [selectedCollectionPoint, setSelectedCollectionPoint] = useState();
  const [dedicatedCollectionPerson, setDedicatedCollectionPerson] = useState();
  const [dedicatedCollectionPersonActive, setDedicatedCollectionPersonActive] =
    useState();
  const { addAlert, removeAlert } = useAlert();
  const bagIsActive = useRef(false);
  const history = useHistory();

  useEffect(() => {
    if (data) {
      if (!data.me.checkout) {
        return;
      }

      setSelectedCollectionPoint(data.me.checkout.shippingMethod);

      let dedicatedCollectionPersonFromMeta = data.me.checkout.meta.find(
        (meta) => meta?.namespace === "dedicatedCollectionPerson"
      )?.clients[0].metadata[0].value;
      setDedicatedCollectionPerson(dedicatedCollectionPersonFromMeta);
      setDedicatedCollectionPersonActive(!!dedicatedCollectionPersonFromMeta);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      if (!data.me.checkout) {
        return;
      }

      // get the time difference between the current time and the last time
      // time the bag was created.  is the bag is older that 15 mins the bag is expired
      const bagCreated = new Date(data.me.checkout.created).toISOString();
      const now = new Date().toISOString();
      var diff =
        (new Date(bagCreated).getTime() - new Date(now).getTime()) / 1000;
      diff /= 60;
      const diffMins = Math.abs(Math.round(diff));
      bagIsActive.current = diffMins <= 15;

      //if (!bagIsActive.current) {
      data.me.checkout.lines.forEach((productLine) => {
        let quantityAllocated = bagIsActive.current ? 1 : 0;

        if (
          productLine.variant.stockQuantity <=
          productLine.variant.quanityAllocatedInCheckout
        ) {
          if (
            productLine.variant.quanityAllocatedInCheckout < quantityAllocated
          ) {
            addAlert(
              `<strong>${productLine.variant.product.name}</strong> has been removed from your bag as it is no longer in stock.`
            );
          }
        }
      });
      //}
    }
  }, [data, addAlert]);

  useEffect(() => {
    if (data) {
      if (!data.me.checkout) {
        return;
      }
      // Remove the product of the month if it the only item left in the bag
      const potmCount = data.me.checkout.lines.filter(
        (line) => line.variant.isProductOfTheMonth
      ).length;
      if (potmCount >= data.me.checkout.lines.length) {
        data.me.checkout.lines.forEach((productLine) => {
          removeProduct(productLine.id);
        });
        removeAlert();
      }
    }
  }, [data, removeAlert, removeProduct]);

  if (loading || error) return null;

  const productIdsInBag = () =>
    data.me.checkout.lines.map((line) => line.variant.product.id);

  const freeGiftCount = () =>
    data.me.checkout.lines.filter((line) => line.variant.isProductOfTheMonth)
      .length;

  const pointsRemainingAfterOrder = () =>
    Math.max(data.me.points - data.me.checkout.subtotalPrice.net.amount, 0);

  const ordersRemainingAfterOrder = () =>
    Math.max(data.me.orderAllowance - 1, 0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function removeProduct(lineId) {
    await deleteCheckoutLineMutation({
      variables: {
        checkoutId: data.me.checkout.id,
        lineId: lineId,
      },
    });
  }

  async function removeOutOfStockItems() {
    data.me.checkout.lines.forEach((productLine) => {
      // If the bag is active then stock is allocated to the user.
      // Add the users allocated stock to the available quantity.
      let quantityAllocated = bagIsActive.current ? 1 : 0;

      if (productLine.variant.stockQuantity < productLine.quantity) {
        removeProduct(productLine.id);
      }
      if (
        productLine.variant.stockQuantity <=
        productLine.variant.quanityAllocatedInCheckout
      ) {
        if (
          quantityAllocated === 0 &&
          productLine.variant.quanityAllocatedInCheckout >=
            productLine.variant.stockQuantity
        ) {
          removeProduct(productLine.id);
        } else if (
          productLine.variant.quanityAllocatedInCheckout < quantityAllocated
        ) {
          removeProduct(productLine.id);
        }
      }
    });
    removeAlert();
  }

  async function removeProductOfTheMonth() {
    if (freeGiftCount() >= data.me.checkout.lines.length) {
      data.me.checkout.lines.forEach((productLine) => {
        removeProduct(productLine.id);
      });
      removeAlert();
    }
  }

  async function updateDedicatedCollectionPersonOnCheckout() {
    return await updateCheckoutMetadataMutation({
      variables: {
        checkoutToken: data.me.checkout.token,
        input: {
          namespace: "dedicatedCollectionPerson",
          clientName: "dedicatedCollectionPerson",
          key: "name",
          value: dedicatedCollectionPerson || "",
        },
      },
    });
  }

  function canContinueToCheckout() {
    if (dedicatedCollectionPersonActive && !dedicatedCollectionPerson) {
      return false;
    }

    if (!selectedCollectionPoint) {
      return false;
    }

    if (!userHasEnoughOrders()) {
      return false;
    }

    if (!userHasEnoughPoints()) {
      return false;
    }

    return true;
  }

  const userHasEnoughOrders = () => {
    return data.me.orderAllowance > 0;
  };

  const userHasEnoughPoints = () => {
    return pointsRemainingAfterOrder() >= 0;
  };

  async function continueToCheckout() {
    await updateDedicatedCollectionPersonOnCheckout();
    await removeOutOfStockItems();
    await removeProductOfTheMonth();
    await refetchCheckoutData();

    return history.push("/checkout");
  }

  if (loading) return <Loader />;

  if (error) {
    return (
      <Layout>
        <WindowTitle title="Bag" />
        <div className="bag">
          <div className="container">
            <h1 className="bag__title">Bag</h1>
            <p>An unknown error occurred.</p>
          </div>
        </div>
      </Layout>
    );
  }

  if (!data.me.checkout || !data.me.checkout.lines.length) {
    return (
      <Layout>
        <WindowTitle title="Bag" />
        <div className="bag">
          <div className="container">
            <h1 className="bag__title">Bag</h1>
            <p className="bag__empty">Your bag is empty.</p>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <WindowTitle title="Bag" />
      <div className="bag">
        <div className="container">
          <h1 className="bag__title">Bag</h1>
          <div className="bag__grid">
            <div className="bag__products">
              {data.me.checkout.lines.map((productLine) => (
                <BagItem
                  product={productLine}
                  removeProduct={removeProduct}
                  bagIsActive={bagIsActive.current}
                  key={productLine.id}
                ></BagItem>
              ))}

              {data.me.orderAllowance === 1 && (
                <ProductUpsell
                  remainingPoints={pointsRemainingAfterOrder()}
                  excludeProducts={productIdsInBag()}
                ></ProductUpsell>
              )}
            </div>
            <aside className="bag__summary">
              <div className="bag__order-summary order-summary">
                <h2 className="order-summary__title">Order Summary</h2>
                <div className="order-summary__row">
                  <div className="order-summary__row-label">items added</div>
                  <div className="order-summary__row-data">
                    {data.me.checkout.lines.length - freeGiftCount()}
                  </div>
                </div>
                <div className="order-summary__row">
                  <div className="order-summary__row-label">free gift</div>
                  <div className="order-summary__row-data">
                    {freeGiftCount()}
                  </div>
                </div>
                <div className="order-summary__total">
                  <div className="order-summary__total-row">
                    <div className="order-summary__total-label">
                      Total items
                    </div>
                    <div className="order-summary__total-data">
                      {data.me.checkout.lines.length}
                    </div>
                  </div>
                  <div className="order-summary__total-row">
                    <div className="order-summary__total-label">
                      Order total{" "}
                      <span className="order-summary__total-label-note">
                        (Employee points)
                      </span>
                    </div>
                    <div className="order-summary__total-data order-summary__total-data--points">
                      {data.me.checkout.subtotalPrice.net.amount} pts
                    </div>
                  </div>
                </div>

                <div className="order-summary__collection-point collection-point">
                  <h3 className="collection-point__title">Collection point</h3>
                  <CollectionPointPicker
                    checkoutId={data.me.checkout.id}
                    selectedCollectionPoint={selectedCollectionPoint}
                    setSelectedCollectionPoint={setSelectedCollectionPoint}
                    collectionPoints={data.me.checkout.availableShippingMethods}
                  />
                  <CollectionPerson
                    dedicatedPerson={dedicatedCollectionPerson}
                    setDedicatedPerson={setDedicatedCollectionPerson}
                    dedicatedPersonActive={dedicatedCollectionPersonActive}
                    setDedicatedPersonActive={
                      setDedicatedCollectionPersonActive
                    }
                    collectionPoint={selectedCollectionPoint}
                  />
                </div>

                <div className="order-summary__actions">
                  <button
                    className="btn order-summary__btn"
                    disabled={!canContinueToCheckout()}
                    onClick={() => continueToCheckout()}
                  >
                    Continue to checkout
                  </button>
                </div>
              </div>

              {!userHasEnoughOrders() && (
                <AlertInline
                  level="error"
                  description="No orders remaining this month. Come back next month"
                />
              )}

              <BagAccountSummary
                remainingPoints={pointsRemainingAfterOrder()}
                remainingOrders={ordersRemainingAfterOrder()}
              />
            </aside>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default View;
