import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuth } from "../../core/hooks/useAuth";

export type SecureRouteProps = {} & RouteProps;

export default function UnauthenticatedRoute({
  ...routeProps
}: SecureRouteProps) {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Route {...routeProps} />;
  }
  return <Redirect to="/" />;
}
