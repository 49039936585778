import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { productUpsellQuery } from "../../views/Product/queries";
import AddToCartButton from "../AddToCartButton";
import ImagePlaceholder from "../../assets/images/product-awaiting-image.png";
import "./index.scss";

const ProductUpsell = ({ excludeProducts, remainingPoints }) => {
  const { loading, data, error } = useQuery(productUpsellQuery, {
    variables: { ids: excludeProducts, pointsRemaining: remainingPoints },
  });
  const [isVisible, setIsVisible] = useState(true);

  if (loading || error || !isVisible) return null;

  const product = data.products.edges[0] && data.products.edges[0].node;

  if (!product || remainingPoints === 0) {
    return null;
  }

  const getProductThumbnail = () => {
    return product.thumbnail ? product.thumbnail.url : ImagePlaceholder;
  };

  const hide = () => {
    return setIsVisible(false);
  };

  return (
    <>
      <div className="bag-upsell">
        <button
          className="bag-upsell__close-btn"
          title="Hide suggestions"
          onClick={() => hide()}
        >
          Hide suggestions
        </button>
        <h3 className="bag-upsell__heading">Use them before you lose them!</h3>
        <p className="bag-upsell__text">
          You will still have <strong>{remainingPoints}pts</strong> left, but
          this is your last purchase for this month... why not spend them on
          this
        </p>
        <div className="bag-upsell__product">
          <div key={product.id} className="product-panel">
            <img
              className="product-panel__image"
              src={getProductThumbnail()}
              alt={product.name}
            ></img>
            <div className="product-panel__body">
              <h2 className="product-panel__title">{product.name}</h2>
              <p className="product-panel__subtitle">{product.subtitle}</p>
              <span className="product-panel__points">
                {product.pricing.priceRange.start.net.amount} points
              </span>
            </div>
          </div>
          <div className="bag-upsell__actions">
            <AddToCartButton variant={product.variants[0]}></AddToCartButton>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductUpsell;
