import React from "react";
import HowItWorksItem from "./howItWorksItem";
import iconBuilding from "../../../assets/images/icon-doterra-hq.svg";
import iconCoins from "../../../assets/images/icon-multiple-points.svg";
import iconGift from "../../../assets/images/icon-free-product.svg";

const HowItWorks = () => {
  // Added steps object for future proofing
  const steps = [
    {
      ImageURL: iconCoins,
      imageAlt: "Multiple Points",
      content:
        "Easily purchase your favorite doTERRA products using your doTERRA Employee Points",
    },
    {
      ImageURL: iconGift,
      imageAlt: "Free Product",
      content:
        "Automatically receive a free Product Of The Month with your first order every single month",
    },
    {
      ImageURL: iconBuilding,
      imageAlt: "Collect",
      content:
        "Conveniently collect your products from your place of work (doTERRA HQ or Lindon Fulfillment Center)",
    },
  ];

  return (
    <section className="promo promo--nobd howitWorks">
      <header className="container promo__header">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h2 className="promo__title promo__title--wst">
              How does it work?
            </h2>
            <h3 className="promo__subtitle">
              Shop your favorite doTERRA products using your employee points?
            </h3>
          </div>
        </div>
      </header>
      <div className="container promo__body">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="promo__list howitWorks__list">
              {steps.map((collectPoint, i) => (
                <HowItWorksItem
                  imageURL={collectPoint.ImageURL}
                  imageAlt={collectPoint.ImageAlt}
                  content={collectPoint.content}
                  key={i}
                ></HowItWorksItem>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
