import React, { useState } from "react";
import ImagePlaceholder from "../../assets/images/product-awaiting-image.png";
import loaderImage from "../../assets/images/loader.gif";

const BagItem = ({ product, removeProduct, bagIsActive }) => {
  const isAvailable = () => {
    if (!bagIsActive) {
      return (
        product.quantity <=
        product.variant.stockQuantity -
          product.variant.quanityAllocatedInCheckout
      );
    } else {
      if (product.variant.stockQuantity < product.quantity) {
        return false;
      } else {
        return true;
      }
    }
  };

  const isProductOfTheMonth = product.variant.isProductOfTheMonth;
  const [isRemoving, setIsRemoving] = useState();

  const price = () => {
    if (product.totalPrice.net.amount === 0) {
      return "FREE";
    }

    return product.totalPrice.net.amount + "pts";
  };

  const getProductThumbnail = () => {
    return product.variant.product.thumbnail
      ? product.variant.product.thumbnail.url
      : ImagePlaceholder;
  };

  const removeFromBagButton = () => {
    if (!product.variant.isProductOfTheMonth) {
      return (
        <button
          className="product-panel__remove"
          onClick={() => {
            setIsRemoving(true);
            removeProduct(product.id);
          }}
          title="Remove from bag"
        >
          &times;
        </button>
      );
    }
  };

  const loadingOverlay = () => {
    if (isRemoving) {
      return (
        <div className="product-panel__overlay">
          <img src={loaderImage} alt="" />
        </div>
      );
    }
  };

  return (
    <>
      <div
        key={product.variant.id}
        className={
          "product-panel " +
          (!isAvailable() ? "product-panel--disabled " : "") +
          (isProductOfTheMonth ? "product-panel--potm " : "")
        }
      >
        {loadingOverlay()}
        <img
          className="product-panel__image"
          src={getProductThumbnail()}
          alt={product.variant.product.name}
        ></img>
        <div className="product-panel__body">
          {product.variant.isProductOfTheMonth && (
            <p className="product-panel__potm-heading">Product of the month</p>
          )}
          <h2 className="product-panel__title">
            {product.variant.product.name}
            {product.variant.name ? " (" + product.variant.name + ")" : ""}
          </h2>
          <p className="product-panel__subtitle">
            {product.variant.product.subtitle}
          </p>
          <p className="product-panel__points">{price()}</p>
        </div>
        {removeFromBagButton()}
      </div>
    </>
  );
};
export default BagItem;
