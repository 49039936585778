export const sortOptions = {
  recentlyAdded: {
    label: "Recently added",
    field: "DATE",
    direction: "DESC",
  },
  aToZ: {
    label: "A-Z",
    field: "NAME",
    direction: "ASC",
  },
  zToA: {
    label: "Z-A",
    field: "NAME",
    direction: "DESC",
  },
  pointsLowToHigh: {
    label: "Points - Low to High",
    field: "PRICE",
    direction: "ASC",
  },
  pointsHighToLow: {
    label: "Points - High to Low",
    field: "PRICE",
    direction: "DESC",
  },
};
