import gql from "graphql-tag";

export const userCheckoutSummary = gql`
  query UserCheckoutSummary {
    me {
      checkout {
        id
        created
        lines {
          id
        }
      }
    }
  }
`;
